@import url('fonts.css');

body {
	font-family: "ProximaNova-Regular";
	padding:0;
    margin:0;
    font-size: 0rem;
}
html {
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
}
*, *:before, *:after {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	padding: 0;	
}
*:focus {
    outline: none;
}

input {
	outline: none;
	border: none;
}

button {
	outline: none !important;
	border: none;
	background: transparent;
}

button:hover {
	cursor: pointer;
}

/* Firefox Bug: link not triggered */
.waves-effect .waves-ripple {
    z-index: -1;
}
/** {
	margin: 0;
	padding: 0;
}*/
*, *:before, *:after {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	padding: 0;	
}
*:focus {
    outline: none;
}
.row::after,
.clearfix::before,
.clearfix::after {
    content: "";
    clear: both;
    display: table;
}
.container,
.container-fluid{
    font-size: 1rem;
}
.loadingwrapper {
    width:100%;
	/*margin: 7em auto;*/
	margin: 0 auto;
    text-align: center;
}
.notfoundwrapper{
	width:100%;
	margin: 1em auto;
	text-align: center;
}
.app-logo {
	max-width:100%;
}
.page-content {
	height: 100vh;
	margin:0;
    padding: 0;
    width:100%;
    position: relative;
    display: table;
    /*padding-bottom: 35px;*/
}
.page-content.footercartopen{
    /*padding-bottom: 35px;*/
    padding-bottom: 85px;
    display: table;
}
.page-content.captionmenu{
    padding-bottom: 50px;
    display: table;
}
.page-content.footercartopen.captionmenu,
.page-content.footerbarshowing.captionmenu{
    padding-bottom: 110px;
    display: table;
}
.page-content.footercartopen,
.page-content.footerbarshowing{
    padding-bottom: 110px;
    display: table;
}
.orderthanks{
    /*background: #E4F3F8;*/
    background: #ffffff;
}
.scannerwrapper {
	max-width: 400px;
	width:100%;
	min-height: 69.5vh;
	background: #F1F2F5;
	position: relative;
	margin:28px auto 0 !important;
}
.scannerwrapper.hide{
    display:none;
}
.qrcodeqrapper {
    position:relative;
    padding: 1.5rem;
    margin: 1rem 1rem 0 1rem;
    width: 100%;
}
.qrcodeqrapper:before,
.qrcodeqrapper:after,
.qrcodeqrapper>:first-child:before,
.qrcodeqrapper>:first-child:after {
    position:absolute;
    width:70px;
    height:70px;
    border-color:#296DD9; /* or whatever colour */
    border-style:solid; /* or whatever style */
    content: ' ';
}
.qrcodeqrapper:before {top:0;left:0;border-width: 1px 0 0 1px}
.qrcodeqrapper:after {top:0;right:0;border-width: 1px 1px 0 0}
.qrcodeqrapper>:first-child:before {bottom:0;right:0;border-width: 0 1px 1px 0}
.qrcodeqrapper>:first-child:after {bottom:0;left:0;border-width: 0 0 1px 1px}
.scannerwrapper .qrcodebox {
	width:100%;
}
.scannerwrapper .qrcodebox section div{
	border:unset !important;
    box-shadow: rgba(6, 44, 183, 0.82) 0px 0px 0px 1px inset !important;
    position: unset !important;
}
.scannerwrapper .additionaloptiontitle{
	position: absolute;
	top: -20px;
	font-weight: bold;
    left: 0;
    right: 0;
    width: 40px;
    height: 40px;
    border-radius: 22px;
    background: #F1F2F7;
    text-align: center;
    line-height: 45px;
	margin: auto;
    box-shadow: 1px 1px 2px #ccc;
    font-size: 16px;
}
.scannerwrapper .scanerrormsg{
	position: absolute;
    top: 95px;
    left: 0;
    right: 0;
    width: 90%;
    text-align: center;
    background-color: #ff0000;
    color:#ffffff;
    margin:auto;
}
.seatcodewrapper{
    position: relative;
}
.seatcodewrapper .seaterrormsg,
.seatcodewrapper .eventerrormsg{
    text-align: center;
    color: #ff0000;
    margin:auto;
    /*height: 24px;*/
}
.page-content .seatcodewrapper {
	width:90%;
	max-width: 400px;
	margin:0 auto 0;
}
.page-content .searchcontainer {
	width:90%;
	max-width: 400px;
	margin:auto;
}
.itemfilter-container {
	position: relative;
	top: 0;
	left: 0;
	height: 100%;
    width: 95%;
    margin: auto;
}
.itemfilter-center {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: left;
}
.searchfilter .checkboxwrapper {
    float: left;
}
.searchfilter .checkboxwrapper .vegnonvegfiltertitle {
    color: #4A5260;
    font-size: 15px;
    display: inline-block;
    margin-right: 8px;
    font-weight: 600;
}
.searchfilter .checkboxwrapper input[type=checkbox]{
	height: 0;
	width: 0;
	visibility: hidden;
}
.searchfilter .checkboxwrapper input[type=checkbox]+label {
	cursor: pointer;
	text-indent: -9999px;
	width: 34px;
	height: 10px;
	background: #b5b2b2;
	display: inline-block;
	border-radius: 100px;
	position: relative;
}
.searchfilter .checkboxwrapper input[type=checkbox]+label:after {
	content: '';
	position: absolute;
	top: -5px;
	left: 0px;
	width: 20px;
	height: 20px;
    background: #ffffff;
    border: 1px solid #b5b2b2;
	border-radius: 90px;
	transition: 0.3s;
}
.searchfilter .checkboxwrapper input[type=checkbox]:checked + label {
	background: #c0efe4;
}
.searchfilter .checkboxwrapper input[type=checkbox]:checked + label:after {
	left: calc(100% - 0px);
    transform: translateX(-100%);
    border: 1px solid #ffffff;
    background: #008265;
}
.searchfilter .checkboxwrapper input[type=checkbox]+label:active:after {
	width: 20px;
}
.searchfilter .searchboxwrapper{
    position: absolute;
    right: 0;
    width: 50px;
    border-left: 1px solid #E5E5E6;
}
.searchfilter .searchboxwrapper .search-icon {
    width: 28px;
    height: 28px;
    display: block;
    background:url("../images/magnifying-glass.svg")center left 0px/28px auto no-repeat;
    float: right;
}
.seatcodewrapper .seatidtitle {
	font-size:18px;
	line-height: 30px;
	font-weight: bold;
	margin-bottom: 25px;
	text-align: left;
}
.seatcodewrapper input[type='text'],
.seatcodewrapper input[type='number'] {
    background:url(../images/input-qr-code.svg) no-repeat 10px 5px;
	padding: 5px 5px 3px 45px;
	height: 2.44rem;
    border: 1px solid gray;
    width: 70%;
	letter-spacing: 1px;
	border-radius: 20px 0 0 20px;
	font-size: 18px;
}
.seatcodewrapper input[type='text']::placeholder,
.seatcodewrapper input[type='number']::placeholder {
    color:#B3B3B9;
}
.seatcodewrapper input[type='text'].floatleft,
.seatcodewrapper input[type='number'].floatleft{
    float: left;
    width: 80%;
}
/*button css code will start here*/
.ripplepink {
    background-position: center;
    transition: background 0.8s;
}
.ripplepink:hover {
    background: #e86477 radial-gradient(circle, transparent 1%, #e86477 1%) center/15000%;
}
.ripplepink:active {
    background-color: #f55a71;
    background-size: 100%;
    transition: background 0s;
}

.seatcodewrapper input[type='button'],
.seatcodewrapper button[type='submit'] {
	padding: 5px 0 7px 0;
	height: 2.5rem;	
	background-color: #E4465D;
	width: 30%;
	color:#ffffff;
	border:0;
	border-radius: 0 20px 20px 0;
	font-size: 20px;
    -webkit-appearance: none;
    outline: none;
}

/*button code will end here*/

.searchcontainer input[type='text'] {
	padding: 6px 3px;
	height: 2.5rem;
    border: 1px solid gray;
    width: 100%;
	letter-spacing: 1px;
	border-radius: 20px;
	font-size: 18px;
}
.searchfilter{
    background-color: #e8e8e8;
    padding: 8px 0;
}
.searchfilter.managemenu{
    position: sticky;
    top: 45px;
    z-index: 1;
}
.searchfilter .searchbox{
    background-color: #ffffff;
    transition: all 0.4s ease;
    width: 100%;
    margin: auto;
    z-index: 0;
    border-radius: 0px;
}
.searchfilter .searchbox.opened{
    top: 5px;
    text-align: center;
}
.searchfilter .searchbox.loggedin{
    width:88%;
    float:left;
    height: 38px;
}

.searchfilter .closesearch{
    font-size: 25px;
}

.searchfilter .searchbox input[type='text'],
.searchfilter .searchbox input[type='number']
{
    width:90%;
    padding: 0 0 0 8px;
    font-size: 20px;
    border: 0;
    height: 30px;
}

/*.searchfilter .searchbox input[type='number'].searchrunnerinput{
    width:72%;
}*/

.searchbox .runnerinputwrapper{
    width: 62%;
    float: left;
}

.searchbox button.releaserunnerbtn{
    text-align: center;
    font-size: 20px;
    width: 100px;
    max-width: 160px;
    padding: 5px;
    border-radius: 5px;
    margin:2px;
    -webkit-appearance: none;
    background-color: #092147;
}

.searchbox button.releaserunnerbtn.acceptnext{
    background-color: #E3465D;
}

.searchbox button.releaserunnerbtn.smallbutton{
    padding: 3px;
    width: 70px;
    font-size: 18px;
}

.smallbannerwrapper{
    position:sticky;
    top:0;
    z-index: 111;
}

.bannerwrapper {
    position: relative;
    z-index:9;
    animation-name: slidingstep1;
    animation-duration: 1s;
}
.bannerwrapper img {
    width: 100%;
    height:auto;
}
.carousel .slide video{
    width: 100%;
    /*height: 28vh;
    max-height: 18rem;*/
}
.eventinfowrapper{
    position: absolute;
    width: 100%;
    margin:auto;
    top:0;
    right: 0;
    bottom: 0;
    left: 0;
    max-height: 80px;
    text-align: center;
}
.eventinfowrapper .eventtitle {
    font-size: 35px;
    color: #FFFFFF;
    font-family: 'Sail-Regular';
}
.eventinfowrapper .eventdate {
    font-size: 18px;
    color: #F5E1BA;
    font-family: 'Libre-Baskerville-Regular';
    font-style: Italic;
}
.category-wrapper {
    padding: 10px 0 5px 0;
	overflow-x: scroll;
	overflow-y: hidden;
	box-sizing: border-box;
}
.category-wrapper ul {
    list-style: none;
    margin: 0 auto;
}
.category-wrapper li {
    float: left;
    background-color: #fff;
    padding: 6px 8px;
    border-radius: 15px;
    margin: 0 3px;
    font-size: 12px;
    letter-spacing: 1px;
    cursor: pointer;
    position: relative;
}
.category-wrapper li:last-child {
	margin-right: 0;
}
.category-wrapper li.selected {
    background-color: #E3465D;
    color: #fff;
}
.titlewrapper {
	background-color: #092147;
    padding: .5rem 1rem;
    clear: both;
}
.titlewrapper.menu{
    background-color: #E3465D;
}
.offline2 .titlewrapper{
    background-color: #c7c7ca;    
}
.titlewrapper h3 {
	color: #ffffff;
	font-weight: normal;
    font-size: 1.7rem;
    margin:0;
}
.titlewrapper h3 span {
	color: #9DDAED;
	font-size: 1.5rem;
}
.managemenu.titlewrapper{
    display: flex;
    padding: .5rem .5rem;
}
.managemenu h3{
    float: left;
    /*width: 95%;*/
}
.managemenu .fa.fa-edit{
    float: right;
    color: #ffffff;
    font-size: 22px;
}
.local-cart-container{
    max-height: 50vh;
    min-height: 40vh;
    overflow: auto;
}
.local-cart-container.editcategory{
    /*max-height: 60vh;
    min-height: 55vh;*/
    max-height: 95vh;
    min-height: 75vh;
}
.local-cart-container.notdeliveredpopup{
    max-height: 70vh;
    min-height: 65vh;
}
.product-listing-wrapper .products-list,
.local-cart-container .cart-products-list
{
	list-style: none;
}
.local-cart-container.confirmseatbox,
.local-cart-container.selecttable
{
    height: 50vh;
    text-align: center;
    padding-top: 10px;
}
.local-cart-container.confirmseatbox{
    padding-left: 10px;
    padding-right: 10px;
}
.local-cart-container.selecttable.engagedrunner{
    padding-top: 0px;
    height:92vh;
    max-height:92vh;
}
.local-cart-container.selecttable.engagedrunner .table{
    font-size: 25px;
    padding: 10px 5px;
    float: left;
    width: 100%;
}
.local-cart-container.selecttable.engagedrunner .table .runnerleftbox{
    float: left;
    width: 50%;
    /*width: 100px;
    display: table-cell;
    vertical-align: middle;*/
}
.local-cart-container.selecttable.engagedrunner .table .runnerrightbox{
    float: right;
    width: 50%;
    min-height: 50px;
    position: relative;
    /*display: table-cell;
    vertical-align: middle;*/
}
.local-cart-container.raisecomplainbox{
    max-height: 35vh;
    min-height: 35vh;
}
.runneridbox{
    float: left;
    background-color: #E3465D;
    padding: 5px;
    width: 50px;
    text-align: center;
    color: #ffffff;
}
.runnernamebox{
    float: left;
    margin-left: 5px;
    font-size: 22px;
}
.engagedrunner .runnermsg{
    padding: 10px 5px;
    background-color: #E3465D;
    color: #FFFFFF;
    margin-bottom: 0px !important;
    position: sticky;
    top: 0;
    z-index: 1;
}
.products-list .product-thumb img
{
	max-width: 100%;
}
.products-list .product-thumb .noitemimage{
    width: 90px;
    height: 90px;
    color: #000000;
    margin: auto;
    font-size: 5rem;
    line-height: 5.5rem;
    text-align: center;
}
.products-list .product-thumb .noitemimage.A,
.products-list .product-thumb .noitemimage.B,
.products-list .product-thumb .noitemimage.C,
.products-list .product-thumb .noitemimage.D,
.products-list .product-thumb .noitemimage.E
{
    background-color:#A4BD47;
}
.products-list .product-thumb .noitemimage.F,
.products-list .product-thumb .noitemimage.G,
.products-list .product-thumb .noitemimage.H,
.products-list .product-thumb .noitemimage.I,
.products-list .product-thumb .noitemimage.J
{
    background-color:#B1A7FF;
}
.products-list .product-thumb .noitemimage.K,
.products-list .product-thumb .noitemimage.L,
.products-list .product-thumb .noitemimage.M,
.products-list .product-thumb .noitemimage.N,
.products-list .product-thumb .noitemimage.O
{
    background-color:#A1C48C;
}
.products-list .product-thumb .noitemimage.P,
.products-list .product-thumb .noitemimage.Q,
.products-list .product-thumb .noitemimage.R,
.products-list .product-thumb .noitemimage.S,
.products-list .product-thumb .noitemimage.T
{
    background-color:#DA8541;
}
.products-list .product-thumb .noitemimage.U,
.products-list .product-thumb .noitemimage.V,
.products-list .product-thumb .noitemimage.W,
.products-list .product-thumb .noitemimage.X,
.products-list .product-thumb .noitemimage.Y,
.products-list .product-thumb .noitemimage.Z
{
    background-color:#C1BE42;
}
.subcat-thumb img
{
	width: 100%;
}
.products-list .product-title-english,
.cart-products-list .product-title-english
{
	font-size: 20px;
	text-align: left;
	color: #092147;
    font-weight: 600;
    text-transform: capitalize;
}
.products-list .product-title-english.stockout,
.cart-products-list .product-title-english.stockout{
    color:#979393;
}
.noicon{
	margin-left:22px;
}
.product-title-english.noicon.manageitem{
    margin-left:0px;
}
.products-list .product-subtitle,
.cart-products-list .product-subtitle
{
	margin-top: 5px;
	font-size: 15px;
	font-style: italic;
	font-family: 'Bellefair-Regular', serif;
}
.products-list .product-title-hindi,
.cart-products-list .product-title-hindi
{
	font-size: 18px;
	text-align: left;
    margin-top: 5px;
    margin-left: 22px;
    width: 60%;
    display: inline-block;
}
.livecounter
{
    color:#E3465D;
    font-style: italic;
    font-size: 15px !important;
}
.cart-products-list .product-title-hindi
{
    margin-left: 0;    
}
.product-listing-wrapper .products-list li,
.local-cart-container .cart-products-list li {
    padding: 10px 10px 10px 10px;
	border-radius: 5px;
	/*border-bottom: 1px solid #E5E5E6;*/
}
.product-listing-wrapper .products-list li.selected,
.local-cart-container .cart-products-list li.selected {
    background-color: #e4e4e4 !important;
}
.products-list .product-price,
.cart-products-list .product-price {
	float: left;
	font-size: 14px;
	margin-top: 7px;
}
.product-qty-wrapper
{
	float: right;
}
.product-listing-wrapper .product-qty-plus-minus-wrapper,
.local-cart-container .product-qty-plus-minus-wrapper,
.product-listing-wrapper .outofstockdiv,
.local-cart-container .outofstockdiv,
.local-cart-container .removeitem {
    position: absolute;
    display: flex;
    align-items: center;
    bottom: 20px;
    right:0;
    width: 120px;
    border-radius: 20px;
    border: 1px solid #E3465D;
    padding: 5px 10px;
    height: 35px;
}
.product-listing-wrapper .outofstockdiv,
.local-cart-container .outofstockdiv{
    width: 120px;
    border: 0px solid #ffffff;
    text-align: right;
    color: #ff0000;
}
.local-cart-container .removeitem{
    width: 40px;
    border: 0px solid #ffffff;
    bottom: 60px;
    right:0;
    font-size: 20px;
}
.offline2 .product-listing-wrapper .product-qty-plus-minus-wrapper,
.offline2 .local-cart-container .product-qty-plus-minus-wrapper{
    border: 1px solid #c7c7ca;
}
.product-qty-minus {
    border-right: 1px solid #E5E5E6;
    cursor: pointer;
    color: #707070;
    width: 25px;
    background-position: center;
    transition: background 0.8s;
}
.product-qty-minus:hover {
    background: rgba(255, 255, 255, 0.45) radial-gradient(circle, transparent 1%, rgba(255, 255, 255, 0.45) 1%) center/15000%;
}
.product-qty-minus:active {
    background-color: rgba(180, 179, 179, 0.45);
    background-size: 100%;
    transition: background 0s;
}
.product-qty-container {
	border-left: 0;
    border-right: 0;
	min-width: 40px;
    box-sizing: border-box;
    text-align: center;
    color: #E3465D;
    font-size: 16px;
}
.product-qty-plus
{
    border-left: 1px solid #E5E5E6;
    padding: 4px;
    cursor: pointer;
    color: #E3465D;
    width: 30px;
    background-position: center;
    transition: background 0.8s;
}
.product-qty-plus:hover {
    background: rgba(255, 255, 255, 0.45) radial-gradient(circle, transparent 1%, rgba(255, 255, 255, 0.45) 1%) center/15000%;
}
.product-qty-plus:active {
    background-color: rgba(180, 179, 179, 0.45);
    background-size: 100%;
    transition: background 0s;
}
.product-qty-add
{
    border-left: 1px solid #E5E5E6;
    cursor: pointer;
    float: right;
    width: 25px;
    padding: 2px;
}
.offline2 .product-qty-container,
.offline2 .product-qty-plus{
    color: #c7c7ca;
}
.product-qty-wrapper input[type='number']
{
	display: none;
}
.product-item-row
{
    padding: 10px 5px 10px 5px;
	display: flex;
    position: relative;
    border-bottom: 1px solid #E5E5E6;
}
.product-item-thumb-wrapper
{
	position:relative;
    width: 45.33%;
    margin-right: 5px;
}
.product-item-thumb-wrapper .product-thumb{
    position: relative;
    height: 90px;
    animation-name: slidingstep1;
    animation-duration: 1s;
}
.product-item-thumb-wrapper .product-thumb img{
    position: absolute;
    max-width: 95%!important;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    max-height: 100%;    
}
.product-listing-wrapper .products-list li .product-item-detail-wrapper,
.local-cart-container .cart-products-list li .product-item-detail-wrapper{
   width: 100%;
   padding-left: 0px;
}
.offline2 .product-qty-add-wrapper{
    border: 1px solid #c7c7ca;
    color: #c7c7ca;
}
.product-listing-wrapper .products-list li .product-qty-add-wrapper,
.local-cart-container .cart-products-list li .product-qty-add-wrapper {
   font-weight: bold;
   letter-spacing: 1px;
   box-sizing: border-box;
   text-align: center;
   padding: 5px 10px;
   min-width: 120px;
   height: 35px;
   text-transform: uppercase;
   position: absolute;
   bottom: 20px;
   right: 0;
   border: 1px solid #E3465D;
   border-radius: 20px;
   color: #E3465D;
   font-size: 16px;
   cursor: pointer;
}
.product-listing-wrapper .products-list li .product-qty-add-wrapper span.additemincart,
.local-cart-container .cart-products-list li .product-qty-add-wrapper span.additemincart {
	min-width: 50px;
	display: inline-flex;
}
.product-listing-wrapper .products-list li .icon-foodsymbol,
.local-cart-container .cart-products-list li .icon-foodsymbol{
   font-size: .50rem;
   margin-top: 2px;
   margin-right: 5px;
   padding: 2px 3px;
   border: 1px solid #cac5c5;
   float: left;
 }
 .product-listing-wrapper .products-list li .non-veg-food,
 .local-cart-container .cart-products-list li .non-veg-food{
   color:#ad4728;
   border:1px solid #ad4728;
 }
 .product-listing-wrapper .products-list li .veg-food,
 .local-cart-container .cart-products-list li .veg-food{
   color:#008000;
   border:1px solid #008000;
 }
 .product-listing-wrapper .products-list li .specification,
 .local-cart-container .cart-products-list li .specification{
   white-space: nowrap;
   margin: 5px 0;
   color: #7e808c;
   font-size: .75rem;
   text-transform: capitalize;
 }
 .product-item-bottom-bar {
    padding: 4px 5px;
    margin: 0 -5px;
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;
}
.product-qty-price-wrapper
{
	display: none;
	color: #B1B1B1;
	margin-top: 5px;
}
.loading-price {
    text-align: center;
}
.price-and-qty-slider-wrapper
{
	display: none;
}
/*--------------------------footer menu style------------------------*/
._387x7 {
    position: fixed;
    bottom: 35px;
    left: 0;
    right: 0;
    z-index: 10;
    pointer-events: none;
}
._387x7.captionmenu{
    bottom: 90px;
}
.footercartopen ._387x7.captionmenu{
    bottom: 150px;
}
.G8xPo {
    height: 100px;
    z-index: 1;
    -webkit-transition: -webkit-transform .25s ease;
    transition: -webkit-transform .25s ease;
    -o-transition: transform .25s ease;
    transition: transform .25s ease;
    transition: transform .25s ease, -webkit-transform .25s ease;
    will-change: transform;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    contain: content;
    position: relative;
}
._3Vj63 {
    position: absolute;
    left: 50%;
    top: 0;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 110px;
    height: 40px;
    pointer-events: auto;
}
._2oCc6 {
    height: 40px;
}
._3LVcW {
    width: 110px;
    height: 45px;
    border-radius: 30px;
    background: #9DDAED;
    color: #092147;
    text-align: center;
    -webkit-box-shadow: 0 5px 10px 0 rgba(0,0,0,.3), 0 2px 1px 0 rgba(93,141,213,.2);
    box-shadow: 0 5px 10px 0 rgba(0,0,0,.3), 0 2px 1px 0 rgba(93,141,213,.2);
    padding: 8px 14px;
    font-weight: 600;
    font-size: 1.5rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-transform: uppercase;
    will-change: transform;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: scale3d(1,1,1);
    transform: scale3d(1,1,1);
    -webkit-transition: -webkit-transform .25s ease-in-out;
    transition: -webkit-transform .25s ease-in-out;
    -o-transition: transform .25s ease-in-out;
    transition: transform .25s ease-in-out;
    transition: transform .25s ease-in-out, -webkit-transform .25s ease-in-out;
    border: 1px solid #ffffff;
}
.icon-menu{
	width:25px;
	height:25px;
	display:block;
	text-indent:-999999px;
	background:url("../images/menu.svg") no-repeat;
    background-position:1% 0;
    margin:auto;    
}
.offline2 ._3LVcW{
    background-color: #c7c7ca;
}
/*--------------------------footer cart style------------------------*/
.footer-cart-bar
{
    background-color: #E3465D;
    position: fixed;
    bottom: -50rem;
    right: 0;
    left: 0;
    opacity: 0;
    transition: all 0.4s ease;
	cursor: pointer;
	z-index:1;
}
.offline2 .footer-cart-bar{
    background-color: #c7c7ca;
}
.footer-cart-bar.opened
{
	opacity: 1;
	bottom: 0;
}
.footer-cart-bar.opened.captionmenu
{
    bottom: 50px;    
}
.footer-internet-message
{
    background-color: green;
    position: fixed;
    bottom: -75rem;
    right: 0;
    left: 0;
    opacity: 0;
    transition: all 0.4s ease;
	cursor: pointer;
    z-index:1;
    text-align: center;
    color:#ffffff;
}
.footer-internet-message.opened
{
    background-color: #ff0000;    
	opacity: 1;
	bottom: 0;
}
.footer-cart-links-wrapper{
    padding: 10px 5px;
    position: relative;
}
ul.footer-cart-left-links,
ul.footer-cart-right-links
{
    float: left;
    list-style: none;
    margin:0;
}
ul.footer-cart-left-links li {
	display: inline-block;
	color: #fff;
	font-size: 18px;
    padding-right: 5px;
    margin-left: 0px;
}
ul.footer-cart-right-links
{
    float: right;
    border-left: 1px solid #fff;
    padding-left: 8px;
}
ul.footer-cart-left-links li,
ul.footer-cart-right-links li
{
	display: inline-block;
	color: #fff;
    font-size: 18px;
    float: left;
}
ul.footer-cart-left-links li .itemlist
{
	font-size: 15px;
}
.popup {  
    position: fixed;
    width: 100%;  
    height: 100%;  
    left: 0;  
    right: 0;  
    bottom: -100rem;
    margin: auto;
    background-color: rgba(0,0,0, 0.7);
    z-index: 10;
    overflow-y: auto;
}
.popup.opened {
    bottom: 0;
}
._13lkS {
    left: 0;
    position: fixed;
    bottom: -120vh;
    pointer-events: auto;
    z-index: 102499999;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    width: 100%;
    transition: all 0.4s ease;
}
._13lkS.opened{
    /*bottom: 0;*/
    bottom: -23px;
    z-index: 9999;
}
._2KabA {
    padding: 32px;
    max-height: 320px;
    overflow: auto;
    background: #fff;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    will-change: transform,opacity;
    -webkit-transform-origin: bottom;
    -ms-transform-origin: bottom;
    transform-origin: bottom;
    opacity: 1;
    -webkit-animation: XvsvK .25s ease-out;
    animation: XvsvK .25s ease-out;
    width: 100%;
}
._2CLBC {
    margin-bottom: 20px;
    font-size: 1.15rem;
    color: #3d4152;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    overflow: hidden;
}
.C3x7h {
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-transform: capitalize;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.bd27q {
    margin-left: 16px;
}
._2KabA.nopadding{
    padding: 5px 10px;
    background: #E3465D;
    color:#FFFFFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.offline2 ._2KabA.nopadding{
    background: #c7c7ca;
}
.viewcarttitle{
    font-size: 18px;
    /*float:left;
    margin-top: 5px;*/
}
.viewcartsubtitle{
    font-size: 15px;
}
.viewcartsubtitle.runnername{
    font-size: 20px;
}
.view-cart-wrapper{
    background: #ffffff;
}
.cart-products-list .product-item-row{
    min-height: 80px;
}
.placeorderwrapper{
    width: 100%;
    margin: auto;
    display: block;
    text-align: center;
    background: #ffffff;
    padding: 15px 0;
    box-shadow: 0px 2px 9px #000029;
}
.placeorderwrapper button{
    color: #ffffff;
    background-color: #092147;
    border: 0;
    text-align: center;
    font-size: 20px;
    width: 90%;
    max-width: 345px;
    padding: 20px;
    border-radius: 40px;
    -webkit-appearance: none;
}
.placeorderwrapper button.placeorderripple {
    background-position: center;
    transition: background 0.8s;
}
.placeorderwrapper button.placeorderripple.orderplacing{
    background-color: #c7c7ca;
}
.placeorderwrapper button.placeorderripple:hover {
    background: #0c3069 radial-gradient(circle, transparent 1%, #0c3069 1%) center/15000%;
}
.placeorderwrapper button.placeorderripple:active {
    background-color: #0c3069;
    background-size: 100%;
    transition: background 0s;
}
.placeorderwrapper button.smallbtn{
    color: #ffffff;
    background: #092147;
    border: 0;
    text-align: center;
    font-size: 20px;
    width: 40%;
    max-width: 170px;
    padding: 12px;
    border-radius: 40px;
	-webkit-appearance: none;
}
.placeorderwrapper button.smallbtnproceed{
    color: #ffffff;
    background: #092147;
    border: 0;
    text-align: center;
    font-size: 20px;
    width: 100%;
    max-width: 325px;
    padding: 12px;
    border-radius: 40px;
	-webkit-appearance: none;
}
.offline2 .placeorderwrapper button{
    background: #c7c7ca;
	-webkit-appearance: none;
}
/*------------------------mobile menu css-------------------*/
.mobile-menu {
    width: 286px;
    position: fixed;
	right: -300px;
    top: 0;
    bottom: 0;
	background-color: #EEEEF2;
    transition: all 0.2s ease;
	z-index: 99999;
	overflow-y: auto;
}
.mobile-menu-wrapper.opened .mobile-menu {
    right: 0;
}
.mobile-menu-wrapper ul {
    list-style: none;
}
.mobile-menu-wrapper ul li {
	padding: 15px 10px;
	text-align: left;
	font-size: 14px;
	display: block;
    letter-spacing: 1px;
    border-bottom: 1px solid #E5E5E6;
}
.mobile-menu-wrapper ul li.loggedinbox{
	padding: 5px;
	background-color: #E3465D;
}
.mobile-menu-wrapper ul li .logintitle{
	font-size: 18px;
	line-height: 35px;
	font-weight: 600;
	color:#ffffff;
	padding-left:5px;
}
.mobile-menu-wrapper ul li .categorytitle_old {
    float:left;
    font-size: 18px;
    color: #092147;
    font-weight: 600;
}
.mobile-menu-wrapper ul li .categoryquantity {
    float:right;
    font-size: 14px;
    color: #4A5260;
}
.menu-overlay {
	position: fixed;
	height: 100%;
	width: 100%;
	left: 0;
	right: 0;
	top: 0;
	background-color: #000;
	opacity: 0;
	z-index: 10000;
	pointer-events: none;
}
.mobile-menu-wrapper.opened .menu-overlay {
	pointer-events: auto;
	opacity: .4;
	-webkit-transition: opacity .4s ease;
	-o-transition: .4s ease opacity;
	-moz-transition: .4s ease opacity;
	transition: opacity .4s ease;
}
.menubanner {
    display: flex;
}
.menubanner img{
    width: 100%;
}
.empty-cart-button {
    float: right;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    color:#E3465D;
    margin-right:5px;
    color: #ffffff;
    font-size: 18px;
}
.empty-cart-button .clear{
    background:url("../images/delete.svg")center left 5px/20px auto no-repeat;
    width: 25px;
    height: 25px;
    display: flex;
    margin: auto;
}
.offline2 .empty-cart-button{
    color:#c7c7ca;
}
.lightbluebg{
    /*background: #E4F3F8;
    display: table;*/
    display: flex;
    height: 100vh;
    width: 100%;
    align-items:center;
    justify-content: center;
}
.notfoundtitle{
    font-size: 45px;
    color: #092147;
}
.notfoundsubtitle{
    font-size: 22px;
    color: #4A5260;
    margin-top: 1.5rem;
    line-height: 35px;
}
.placeneworderbtn{
    display: block;
    margin-top:2rem;
}
.placeneworderbtn button{
    font-family: "ProximaNova-Regular";
    border:none;
    background-color: #092147;
    color: #ffffff;
    font-size: 20px;
    padding: 20px 30px;
    border-radius:35px;
    width: 90%;
	-webkit-appearance: none;
}
.placeneworderbtn button.jomat{
    background-color: rgb(237, 90, 107);
}
.orderstartinstruction{
    font-size: 1.2rem;
    font-weight: 600;
    color: #000029;
    padding:25px 10px 15px;
    text-align: center;
    border-bottom: 1px solid #E5E5E6;
}
.orderstartinstruction .redtext{
    color: #E4465D;
}
ul.footer-cart-right-links li .icon-cart{
    background:url("../images/shopping-bag.svg")center left 0px/20px auto no-repeat;
    width: 25px;
    height: 25px;
    display: flex;
    margin: auto;
    position: relative;
}
ul.footer-cart-right-links li .icon-cart .pendingqty{
    top: -5px;
    right: -8px;
    width: 20px;
    height: 20px;
    border-radius: 15px;
    line-height: 21px;
    background: #9DDAED;
    color: #000000;
    position: absolute;
    text-align: center;
    -webkit-box-shadow: 0 5px 10px 0 rgba(0,0,0,.3), 0 2px 1px 0 rgba(93,141,213,.2);
    box-shadow: 0 5px 10px 0 rgba(0,0,0,.3), 0 2px 1px 0 rgba(93,141,213,.2);
    /*border: 1px solid #ffffff;*/
}
ul.footer-cart-right-links li.vieworder{
    font-size: 16px;
    margin: auto 8px;
    background-position: center;
    transition: background 0.8s;
}
ul.footer-cart-right-links li.vieworder:active {
    background-color: rgba(0, 0, 0, 0.2);
    background-size: 100%;
    transition: background 0s;
}
ul.footer-cart-right-links li.placeorder{
    width: 115px;
    background: #000029;
    text-align: center;
    font-size: 16px;
    padding: 10px 8px;
    border-radius: 20px;
    margin-top: 5px;
}
ul.footer-cart-right-links li.placeorder {
    background-position: center;
    transition: background 0.8s;
}
ul.footer-cart-right-links li.placeorder:hover {
    background: #26267d radial-gradient(circle, transparent 1%, #26267d 1%) center/15000%;
}
ul.footer-cart-right-links li.placeorder:active {
    background-color: #12127d;
    background-size: 100%;
    transition: background 0s;
}
.offline2 ul.footer-cart-right-links li.placeorder{
    background: #9e9ea9;
}
.closecartpopup{
    background:url("../images/closecart.svg")center left 5px/20px auto no-repeat;
    width: 25px;
    height: 25px;
    /*float:right;
    margin-top: 12px;*/
}
.iconincrease{
	width:20px;
	height:22px;
	display:block;
	text-indent:-999999px;
	background:url("../images/increase_new.svg") no-repeat;
    background-position:1% 0px;
    margin:auto;
    float: right;
}
.icondecrease{
	width:30px;
	height:22px;
	display:block;
	text-indent:-999999px;
	background:url("../images/substract.svg") no-repeat;
    background-position:1% 0;
    margin:auto;
}
.confirmmessaage{
    margin: 3rem auto 2rem;
    display: table;
    font-size: 1.2rem;
}
.orloseattitle{
    font-size: 22px;
}
.orloseatcode{
    font-size: 45px;
    font-weight: 600;
    color: #092147;
}
.confirmseatbox button,
.selecttable button,
.orderingerror button
{
    color: #ffffff;
    border: 0;
    text-align: center;
    font-size: 18px;
    width: 150px;
    max-width: 160px;
    padding: 10px;
    border-radius: 5px;
    margin:2px;
	-webkit-appearance: none;
}
.confirmseatbox .yes,
.selecttable .table,
.orderingerror .retry
{
    background: #9DDAED;
    width: 100%;
    color: #092147;
    border-radius: 35px;
    outline: none;
}
.selecttable .table{
    border-radius: 0px;
}
.selecttable .ordermessage{
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
}
.selecttable .availableseat{
    max-height: 36vh;
    overflow: auto;
    border-bottom: 2px solid #E3465D;
    padding: 0 0 5px 0;
}
.confirmseatbox .yes.orderplacing,
.confirmseatbox .yes:active.orderplacing
{
    background: #c7c7ca;
}
.confirmseatbox .no{
    background: #9DDAED;
    width: 100%;
    color: #092147;
    border-radius: 35px;
}
.confirmseatbox .yes,
.confirmseatbox .no{
    background-position: center;
    transition: background 0.8s;
}
.confirmseatbox .yes:hover,
.confirmseatbox .no:hover{
    background: #6bc2df radial-gradient(circle, transparent 1%, #6bc2df 1%) center/15000%;
}
.confirmseatbox .yes:active,
.confirmseatbox .no:active{
    background-color: #92cde0;
    background-size: 100%;
    transition: background 0s;
}
.flexcontainer{
    display:flex;
	width:100%;
}
.alloweditem{
    color: #ff0000;
}
.closesearchicon{
    background:url("../images/clearsearch.png")center left 5px/15px auto no-repeat;
    width: 25px;
    height: 25px;
    float:right;
	margin-top: 8px;
    margin-right: 5px;
}
.imagecontainer img {
    height: 100px;
    max-width: 100%;
}
.imageloading {
    opacity: 0;
    transition: opacity .3s ease-out;
}
.imageloaded {
    opacity: 1!important;
}
.scan-logo-wrapper{
    margin:0;
    padding: 0;
    text-align: center;
}
.scan-logo {
    width:70px;
}
.nomargin{
    margin: 0;
}
.nopadding{
    padding: 0;
}
.marginauto{
    margin:auto;
}
.mb15{
    margin-bottom:15px;
}
.mt20{
    margin-top:20px;
}
.scancodetitle{
    color:#E3465D;
    display:block;
    text-align:center;
    font-weight: 600;
    font-size: 22px;
}
.scancodesubtitle{
    color:#092147;
    display:block;
    text-align:center;
    font-weight: 600;
    font-size: 18px;    
}
/*splash loading screen start*/
#splash-screen {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #E4F3F8;
    z-index: 99999;
    pointer-events: none;
}
#splash-screen .center {
    display: block;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
#splash-screen .logo {
    width: 128px;
    margin: 0 auto;
}
#splash-screen .logo img {
    filter: drop-shadow(0px 10px 6px rgba(0, 0, 0, 0.2))
}
#splash-screen .spinner-wrapper {
    display: block;
    position: relative;
    width: 100%;
    min-height: 100px;
    height: 100px;
}
#splash-screen .spinner-wrapper .spinner {
    position: absolute;
    overflow: hidden;
    left: 50%;
    margin-left: -50px;
    animation: outer-rotate 2.91667s linear infinite;
}
#splash-screen .spinner-wrapper .spinner .inner {
    width: 100px;
    height: 100px;
    position: relative;
    animation: sporadic-rotate 5.25s cubic-bezier(0.35, 0, 0.25, 1) infinite;
}
#splash-screen .spinner-wrapper .spinner .inner .gap {
    position: absolute;
    left: 49px;
    right: 49px;
    top: 0;
    bottom: 0;
    border-top: 10px solid;
    box-sizing: border-box;
}
#splash-screen .spinner-wrapper .spinner .inner .left,
#splash-screen .spinner-wrapper .spinner .inner .right {
    position: absolute;
    top: 0;
    height: 100px;
    width: 50px;
    overflow: hidden;
}
#splash-screen .spinner-wrapper .spinner .inner .left .half-circle,
#splash-screen .spinner-wrapper .spinner .inner .right .half-circle {
    position: absolute;
    top: 0;
    width: 100px;
    height: 100px;
    box-sizing: border-box;
    border: 10px solid #172e54;
    border-bottom-color: transparent;
    border-radius: 50%;
}
#splash-screen .spinner-wrapper .spinner .inner .left {
    left: 0;
}
#splash-screen .spinner-wrapper .spinner .inner .left .half-circle {
    left: 0;
    border-right-color: transparent;
    animation: left-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
    -webkit-animation: left-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
}
#splash-screen .spinner-wrapper .spinner .inner .right {
    right: 0;
}
#splash-screen .spinner-wrapper .spinner .inner .right .half-circle {
    right: 0;
    border-left-color: transparent;
    animation: right-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
    -webkit-animation: right-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
}
.cancelorder{
    color:#ff0000;
}
.notdeliveredtitle{
    color:#092147;
    font-size: 15px;
}
.raisecomplainttxt{
    color:#ff0000;
    font-size: 15px;
}
@keyframes outer-rotate {
    0% {
        transform: rotate(0deg) scale(0.5);
    }
    100% {
        transform: rotate(360deg) scale(0.5);
    }
}
@keyframes left-wobble {
    0%, 100% {
        transform: rotate(130deg);
    }
    50% {
        transform: rotate(-5deg);
    }
}
@keyframes right-wobble {
    0%, 100% {
        transform: rotate(-130deg);
    }
    50% {
        transform: rotate(5deg);
    }
}
@keyframes sporadic-rotate {
    12.5% {
        transform: rotate(135deg);
    }
    25% {
        transform: rotate(270deg);
    }
    37.5% {
        transform: rotate(405deg);
    }
    50% {
        transform: rotate(540deg);
    }
    62.5% {
        transform: rotate(675deg);
    }
    75% {
        transform: rotate(810deg);
    }
    87.5% {
        transform: rotate(945deg);
    }
    100% {
        transform: rotate(1080deg);
    }
}
/*splash loading screen end*/

.appwidth50 {
    width: 50% !important;
    max-width: 50% !important;
    flex: auto;
}

.appwidth100 {
    width:100%;
    text-align: center;
}

.bannerloading{
    min-height: 26.6vh;
}

@keyframes slidingstep1 {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
}

@-webkit-keyframes qty_slider_animation {
    from {left: 7px}
    to {left: 70px}
}

@keyframes qty_slider_animation {
    from {left: 7px}
    to {left: 70px}
}

.qty_slider_animation
{
    position: absolute;
    bottom: -1px;
    width: 20px;
    height: 2px;
    display: block;
    background-color: #d42d47;
    -webkit-animation: qty_slider_animation 0.6s ease infinite;
    animation: qty_slider_animation 0.6s ease infinite;
    right: 0;
}

.loadingitemtitle{
    display: inline;
    width: 89.5%;
    float: left;
}

.product-listing-wrapper .products-list li .product-qty-add-wrapper.loading{
    border: 0px solid #e8e8e8;
    padding: 0px 0px;
    font-size: 22px;
}

.product-listing-wrapper .products-list li .product-qty-add-wrapper.loading .react-loading-skeleton{
    border-radius: 40px;
    height: 28px;
}

.categorytitle_oldloading{
    width:65%;
    display: inline-block;
    margin-right: 5px;
    font-size: 1.5rem;
}

.categoryquantityloading{
    width:25px;
    display: inline-block;
    font-size: 1.5rem;
}

.qrcodeloading{
    width:90%;
    margin:15px auto 0;
}

.stockoutitemlist{
    font-size: 1.2rem;
    margin-top:5px;
    font-weight: 600;
}

.scrollablestockoutitem{
    height:60px;
    width:100%;
    overflow:auto;
}

/*-------login css start-------*/
.limiter {
	width: 100%;
	margin: 0 auto;
}

.container-login100 {
	width: 100%;  
	min-height: 90vh;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	padding: 15px;
}

.wrap-login100 {
	margin-top: 0;
	width: 100%;
	border-radius: 10px;
	overflow: hidden;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.login100-pic {
    width: 100%;
    text-align: center;
}

.login100-pic img {
	max-width: 100%;
}

.login100-form {
	width: 100%;
	padding: 1.5rem 15px;
}

.login100-form-icon {
	text-align: center;
	width: 100%;
	display: block;
	margin-bottom: 10px;
}

.login100-form-icon img{
    width: 60px;
}

.login100-form-title {
	font-size: 24px;
	color: #333333;
	line-height: 1.2;
	text-align: center;
	width: 100%;
	display: block;
	padding-bottom: 54px;
	font-weight: 600;
	text-transform: uppercase;
}

.wrap-input100 {
	position: relative;
	width: 100%;
	z-index: 1;
	margin-bottom: 10px;
}

.input100 {
	font-size: 18px;
	line-height: 1.5;
	color: #666666;
	display: block;
	width: 100%;
	height: 50px;
    border: 1px solid #b1aeae;
    padding: 0 5px;
}

.login100-form-btn {
	font-size: 15px;
	line-height: 1.5;
	color: #fff;
	width: 100%;
	height: 50px;
	border-radius: 5px;
	background: #E3465D;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 25px;
	-webkit-transition: all 0.4s;
	-o-transition: all 0.4s;
	-moz-transition: all 0.4s;
	transition: all 0.4s;
}
  
.login100-form-btn:hover {
	background: #092147;
}

.login100-form-btn[disabled] {
    cursor: not-allowed;
    filter: alpha(opacity=65);
    opacity: 0.65;
    box-shadow: none;
}

.login100-form-btn[disabled]:hover {
	background: #E3465D;
}
/*-------login css end-------*/

.loginbtnwrapper{
    float: right;
    width: 50px;
    margin-top: 10px;
}

.width110{
    width: 110px;
}

.loginbtnwrapper .login100-form-btn{
    padding: 5px 0;
    line-height: 1;
    height:30px;
    margin-top: 10px;
}

.loginicon{
    background:url("../images/user.svg")center left 10px/25px auto no-repeat;
    width: 35px;
    height: 30px;
    float:right;
}

.scanicon{
    background:url("../images/login-qr.svg")center left 10px/25px auto no-repeat;
    width: 35px;
    height: 30px;
    float:right;
}

.stockoutproduct{
    color:#ff0000;
}

.logged-in-header {
	background: #092147; /* For browsers that do not support gradients */    
	background: -webkit-linear-gradient(left, #0b306b, #092147); /* For Safari 5.1 to 6.0 */
	background: -o-linear-gradient(right, #0b306b, #092147); /* For Opera 11.1 to 12.0 */
	background: -moz-linear-gradient(right, #0b306b, #092147); /* For Firefox 3.6 to 15 */
	background: linear-gradient(to right, #0b306b, #092147); /* Standard syntax (must be last) */
	color: #092147;
	height: 56px;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	z-index: 10000;
}

.logged-in-wrapper {
	background: #092147; /* For browsers that do not support gradients */    
	color: #092147;
    z-index: 10000;
    float:right;
}

.account-logout-button {
    float: right;
    margin: 0.5rem 1rem 0.5rem 1rem;
    padding: .2rem 0px;
	cursor: pointer;
}

.logoutbtn {
	font-size: 18px;
	color: #fff;
	background: #092147;
}

.logoutbtn img{
	width:30px;
}

/*@media only screen and (min-width: 601px) and (max-width: 962px) {
    .searchfilter.loggedin{
        width: 91%;
    }
}*/

.loginform .squared{
    margin:auto;
    text-align: center;
}

.loginform .squared input[type=checkbox],.squared input[type=radio]{display:none;}
.loginform .squared input[type=radio] + label:before{content:"";display:inline-block;width:25px;height:25px;vertical-align:middle;margin-right:8px;background-color:#fff;border:2px solid #AFAFAF;-webkit-transition:border-color ease-in-out .15s,box-shadow ease-in-out .15s;transition:border-color ease-in-out .15s,box-shadow ease-in-out .15s;border-radius:15px;}
.loginform .squared input[type=radio]:checked + label:before{content:"\2022";color:#333333;font-size:50px;text-align:center;line-height:28px;text-shadow:0px 0px 3px #ffffff;}
.loginform .squared label{margin-right:5px;font-weight: 600;}

.appelement{
    padding: 8px 0;
    background-color: #092147;
    color: #FFFFFF;
}

.appelement .squared input[type=checkbox],
.appelement .squared input[type=radio]{display:none;}
.appelement .squared label{float: none;width: unset;text-transform:unset;font-weight: normal;line-height: unset;padding-bottom: unset;margin:0;font-size: 18px;vertical-align: middle;}
.appelement .squared input[type=radio] + label:before{content:"";display:inline-block;width:20px;height:20px;vertical-align:middle;margin-right:8px;background-color:#fff;border:2px solid #AFAFAF;-webkit-transition:border-color ease-in-out .15s,box-shadow ease-in-out .15s;transition:border-color ease-in-out .15s,box-shadow ease-in-out .15s;border-radius:15px;}
.appelement .squared input[type=radio]:checked + label:before{content:"\2022";color:#333333;font-size:34px;text-align:center;line-height:16px;text-shadow:0px 0px 3px #ffffff;}
.appelement .squared input[type=checkbox] + label:before{content:"";display:inline-block;width:20px;height:20px;vertical-align:middle;margin-right:8px;background-color:#fff;border:2px solid #AFAFAF;-webkit-transition:border-color ease-in-out .15s,box-shadow ease-in-out .15s;transition:border-color ease-in-out .15s,box-shadow ease-in-out .15s;border-radius:2px;}
.appelement .squared input[type=checkbox]:checked + label:before{content:"\2713";font-size:20px;color:#333333;text-align:center;line-height:20px;text-shadow:0px 0px 3px #ffffff;}

.view-cart-wrapper.orderingerror{
    height: 50vh;
}

.orderingerrormsg{
    margin: 3rem auto 2rem;
    display: table;
    font-size: 1.2rem;
}

.orderingerror .retry{
    width: 100%;
    max-width: 200px;
    margin: auto;
}

.actionbuttonwrapper{
    padding: 10px 8px;
    min-height: 50px;
    text-align: center;
    text-transform: uppercase;
    display: inline-block;
    width: auto;
}

.actionbuttonwrapper button {
	background-color: #092147;
	color:#ffffff;
	border:0;
	font-size: 18px;
    -webkit-appearance: none;
    outline: none;
    padding: 2px 5px;
}

/*.footer-caption-action-bar
{
    background-color: #E3465D;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: 1;
    transition: all 0.4s ease;
	cursor: pointer;
    z-index:1;
    min-height: 50px;
    border-top: 1px solid #000029;
}*/

.footer-caption-action-bar {
    height: 52px;
    display: flex;
    justify-content: center;
    background-color: #092147;
    width: 100%;
    position: fixed;
    bottom: -1px;
    right: 0;
    left: 0;
    opacity: 1;
    z-index: 1;
}
.MuiButtonBase-root {
    color: inherit;
    border: 0;
    cursor: pointer;
    margin: 0;
    display: inline-flex;
    outline: 0;
    padding: 0;
    position: relative;
    align-items: center;
    user-select: none;
    border-radius: 0;
    vertical-align: middle;
    -moz-appearance: none;
    justify-content: center;
    text-decoration: none;
    background-color: transparent;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}
.MuiBottomNavigationAction-root {
    flex: 1;
    color: #ffffff;
    padding: 6px 12px 8px;
    max-width: 168px;
    min-width: 80px;
    transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,padding-top 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.MuiBottomNavigationAction-root.Mui-selected {
    color: #ffffff;
    padding-top: 6px;
}
.MuiBottomNavigationAction-wrapper {
    width: 100%;
    display: inline-flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.MuiSvgIcon-root {
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 1.5rem !important;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
}
.MuiBottomNavigationAction-label {
    opacity: 1;
    font-size: 0.75rem;
    transition: font-size 0.2s, opacity 0.2s;
    transition-delay: 0.1s;
}
.MuiBottomNavigationAction-label.Mui-selected {
    font-size: 0.875rem;
}
.engagedstatus{
    color: #ff0000;
    font-size: 16px;
    text-align: right;
    position: absolute;
    bottom: -8px;
    right: 0;
}
.tokendetail{
    color: #092147;
    font-size: 28px;
    text-align: right;
    position: absolute;
    top: -15px;
    right: 0;    
}
.container.orderedlist{
    width: 100%;
    max-width: none;
    padding-right:0px;
    padding-left:0px;
}
.container.orderedlist .local-cart-container .cart-products-list li{
    padding: 5px 0 5px 0;
}
.container.orderedlist .cart-products-list .product-item-row{
    padding: 0;
    min-height: 60px;
}
.container.orderedlist .cart-products-list .product-item-row.ordertokendetail{
    min-height: 40px;
}
.container.orderedlist .cart-products-list .product-title-english{
    font-size: 18px;
    width: 70%;
    float: left;
    font-weight: 600;
}
.container.orderedlist .cart-products-list .product-title-english.canceleditem{
    text-decoration:line-through;
}
.container.orderedlist .cart-products-list .product-status-wrapper{
    width: 30%;
    float: right;
}

.container.orderedlist .cart-products-list .product-status-wrapper .product-status.red{
    color: #ff0000;
}
.container.orderedlist .cart-products-list .product-status-wrapper .product-status.green{
    color: #008000;
}
.container.orderedlist .cart-products-list .product-status-wrapper .runnername{
    color: #092147;
    margin-top: 5px;
    font-weight: 600;
}
.container.orderedlist .local-cart-container.noitem{
    font-size: 20px;
    color: #ff0000;
    padding: 2rem 0;
    text-align: center;
}
.redstrip{
    background-color: #ff0000;
    color: #FFFFFF;
    padding: 8px 5px;
    display: table;
    width: 100%;
    font-size: 18px;
}
.queuepositiontext{
    float: left;
}
.waittimetext{
    float: right;
}
.queueposition,
.waittime{
    color: yellow;
    font-weight: 600;
    font-size: 24px;
}

.manageitemwrapper{
    /*position: absolute;
    bottom: 0;
    right: 0;*/
    float: left;
    width: 100%;
    padding: 5px 0 5px;
}

.manageitem{
    float: left;
    /*margin-right: 10px;*/
    width: 100%;
    margin-bottom: 12px;
}

.manageitem.stockout{
    color:#979393;
}

.manageitem .managecategory{
    float: right;
    width: 40%;
    margin-bottom: 10px;
}

.manageitem .title{
    color: #000000;
    font-size: 15px;
    font-weight: 600;
    /*margin-bottom: 5px;*/
    display: inline;
}

.manageitem .title.stockout{
    color:#979393;
}

.manageitem input[type=checkbox]{
	height: 0;
	width: 0;
	visibility: hidden;
}
.manageitem input[type=checkbox]+label {
	cursor: pointer;
	text-indent: -9999px;
	width: 40px;
	height: 14px;
	background: #b5b2b2;
	/*display: inline-block;*/
	border-radius: 100px;
    position: relative;
    float: right;
}
.manageitem input[type=checkbox]+label:after {
	content: '';
	position: absolute;
	top: -3px;
	left: 0px;
	width: 20px;
	height: 20px;
    background: #ffffff;
    border: 1px solid #b5b2b2;
	border-radius: 90px;
	transition: 0.3s;
}
.manageitem input[type=checkbox]:checked + label {
	background: #82ebd4;
}
.manageitem input[type=checkbox]:checked + label:after {
	left: calc(100% - 0px);
    transform: translateX(-100%);
    border: 1px solid #ffffff;
    background: #008265;
}
.manageitem input[type=checkbox]+label:active:after {
	width: 20px;
}

.control-label{
    text-align: left;
    font-weight: 600;
}

.savebutton{
    background-color: #092147;
    padding: 10px !important;
    width: 100% !important;
    max-width: 100% !important;
    border-radius: 5px !important;
    color: #ffffff !important;
}

.savebutton.menuitem{
    background-color: #E3465D;
}

.savebutton.width40{
    width:40% !important;
}

.savebutton.width50{
    width:50% !important;
}

.savebutton.width48{
    width:48% !important;
}

.savebutton.width45px{
    width:45px !important;
}

.false {
    border: 1px solid red;
}

.stockmanagement{
    position: relative;
    margin-bottom: 12rem;
}

.stockmanagement .quickmanagestockbox_bak{
    width: 120px;
    height: 40px;
    padding: 5px;
    color: #ffffff;
    margin-right: 15px;
    background-color: #E3465D;
    border-radius: 5px;
    font-size: 15px;
    line-height: 28px;
    float: right;
}

.stockmanagement .quickmanagestockbox{
    position: fixed;
    bottom: 60px;
    right: 10px;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background-color: #E3465D;
    box-shadow: 0 3px 3px rgba(0,0,0,0.3),0 6px 10px rgba(0,0,0,0.5)!important;
    font-size: 25px;
    padding: 8px 0;
    color: #ffffff;
    text-align: center;
}

.squared input[type=checkbox],.squared input[type=radio]{display:none;}

.squared input[type=radio] + label:before{content:"";display:inline-block;width:25px;height:25px;vertical-align:middle;margin-right:8px;background-color:#fff;border:2px solid #AFAFAF;-webkit-transition:border-color ease-in-out .15s,box-shadow ease-in-out .15s;transition:border-color ease-in-out .15s,box-shadow ease-in-out .15s;border-radius:15px;}

.squared input[type=radio]:checked + label:before{content:"\2022";color:#333333;font-size:27px;text-align:center;line-height:22px;text-shadow:0px 0px 3px #ffffff;}

.squared input[type=checkbox] + label:before{content:"";display:inline-block;width:25px;height:25px;vertical-align:middle;margin-right:8px;background-color:#fff;border:2px solid #AFAFAF;-webkit-transition:border-color ease-in-out .15s,box-shadow ease-in-out .15s;transition:border-color ease-in-out .15s,box-shadow ease-in-out .15s;border-radius:2px;}

.squared input[type=checkbox]:checked + label:before{content:"\2713";font-size:30px;color:#333333;text-align:center;line-height:18px;text-shadow:0px 0px 3px #ffffff;}

.form-group .lefttext{
    text-align: left;
}

.product-item-row.categorywrapper{
    padding: 5px 0;
}

.manageitem .managecategory .title{
    float: left;
}

.form-control.orderby{
    width: 30%;
    float: right;
}
.form-control.larg-text{
    font-size: 1.2rem;
}

.bolditem{
    font-weight: 600;
}

.itemname{
    width: 80%;
    float: left;
}

.itemname .itemhindiname{
    font-weight: 500;
}

.itemqty{
    width: 12%;
    float: right;
}

.itemqty.center{
    text-align: center;
}

.itemheader{
    background-color: #EEEEEE;
}

.itemname.itemheader{
    padding: 5px 2px;
}

.itemqty.itemheader{
    padding: 5px 2px;
}

.form-group.margintop30{
    margin-top: 8rem;
}

.form-group.selecttablebox{
    float: left;
    margin-bottom: 5px;
    margin-top: 5px;
    width: 55%;
}
.form-group.cancelallpendingitem{
    width: 100px;
    background: #000029;
    text-align: center;
    font-size: 14px;
    padding: 8px 5px;
    border-radius: 20px;
    margin:8px 5px;
    float:right;
}
.viewcarttitle.selecttablebox{
    margin-top: 12px;
}

.col-sm-2.fullwidth,
.col-sm-10.fullwidth{
    flex: 0 0 100%;
    max-width: 100%;
}

.yt-live-chat-item-list-renderer {
    background-color: #092147;
    border-radius: 999px;
    bottom: 55px;
    left:0;
    right: 0;
    color: #fff;
    cursor: pointer;
    margin: 0 calc(50% - 16px) 0px calc(50% - 16px);
    position: absolute;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,.3), 0 2px 1px 0 rgba(93,141,213,.2);
}

#show-more {
    display: inline-block;
    box-sizing: border-box;
    font-size: 0;
    z-index:9999;
}

button.yt-icon-button {
    vertical-align: middle;
    color: inherit;
    outline: none;
    background: none;
    margin: 0;
    border: none;
    padding: 0;
    width: 100%;
    height: 100%;
    line-height: 0;
    cursor: pointer;
}

#yt-icon, .yt-icon-container.yt-icon {
    fill: currentcolor;    
}

.app-body-noscroll,
.cartopen,
.editcategoryopen,
.notdeliveritemopen,
.orderstatusopen,
.tokendetailopen {
    overflow: hidden;
}

/*------------------react autocomplete style start---------------*/
.runnersearchwrapper{
	position:relative;
}

.runnersearchresult {
    background: #fff;
    max-height: 550px;
	/*width: 302px;*/
	width: 450px;
    position: absolute;
    top: 40px;
	z-index:1;
}

.managebannerwrapper{
    display: block;
    float: left;
    width: 100%;
    border: 2px solid #e8e8e8;
    margin-bottom: 5px;
}

.managebannerwrapper .banner img{
    width: 100%;
    height:auto;
}
.managebannerwrapper .banneroption{
    display: block;
    float: left;
    width: 100%;
    background-color: #e8e8e8;
    padding: 5px 2px;
}

.banneroption .edit{
    float: left;
    display: block;
    width: 70px;
    padding: 1px 0;
    text-align: center;
    border: 1px solid #CCC;
    color: #007aff;
}

.banneroption .delete{
    float: right;
    display: block;
    width: 75px;
    padding: 1px 0;
    text-align: center;
    border: 1px solid #CCC;
    color: #007aff;
}

@media screen and (max-width:768px) {
    .runnersearchresult {
        left: 0;
        max-width: 100%;
        right: 0;
        top: 45px;
        z-index: 1001
    }
}

.runnersearchresult .resultList {
    max-height: 280px;
	overflow-y: auto;
	margin:0;
	padding: 0;
}

.resultList li {
    border-top: 1px solid #f5f5f5;
    border-bottom: 1px solid #f5f5f5;
	line-height: normal;
	clear: both;
	float: left;
	width: 100%;
	padding: 5px 0;
}

.resultList li:hover {
    background: #f5f5f5
}

.resultList li .detail {
    color: #000000;
    display: block;
	padding: 6px 5px;
	cursor: pointer;
	float: left;
	width: 78.8%;
}

.resultList li .detail .colorpink {
    color: #E3465D;
}

.resultList li .detail:hover {
    color: #000000;
}

.recenttoken{
	transition: background 0.8s;
	width: 150px;
	float: left;
	margin-right: 8px;
}

.recenttoken:hover{
	background-color: #c01f38;
}

.optiontitle{
	color:#000000;
	text-decoration: underline;
	cursor: pointer;
}

/*------------------react autocomplete style end---------------*/

input[type='button'].ripplepink{
	padding: 0 2px;
	height: 2rem;	
	background-color: #E4465D;
	width: 60px;
	color:#ffffff;
	border:0;
	font-size: 14px;
    -webkit-appearance: none;
	outline: none;
	margin-right: 2px;
}

input[type='button'].ripplepink.floatleft{
    float: left;
    height: 2.4rem;
}

.separator{
	border: 1px solid #DCDFE2;
	margin: 8px 0;
	clear: both;
}

.batchorder .ordersummary:last-child{
	border: 0px solid #DCDFE2;
}

.ordertitle{
	font-size: 18px;
	font-weight: 600;
	margin: 15px 0;
}

.recordset {
	margin: auto;
	display: inline-block;
	vertical-align: top;
}
.orderbatch{
	margin: 10px 0;
	text-align: center;
	cursor: pointer;
	display: flex;
	min-width: 160px;
	color: #ffffff;
    font-size: 18px;
    margin: auto;
}

.orderbatch .batch{
	padding: 8px 10px;
    border-radius: 5px 0 0 5px;
    width: 50px;
    display: block;
    float: left;
}

.orderbatch .batch1{
	background: #389885;
}

.orderbatch .batch2{
	background: #6C2B8D;
}

.orderbatch .batch3{
	background: #468CC4;
}

.orderbatch .batch4{
	background: #CF2E21;
}

.orderbatch .batchvip{
	background: #CC9813;
}

.orderbatch .orders{
	padding: 8px 10px;
    border-radius: 0 5px 5px 0;
    min-width: 270px;
    display: block;
}

.orderbatch .orders1{
	background: #64BFAD;
}

.orderbatch .orders2{
	background: #8E51AC;
}

.orderbatch .orders3{
	background: #6DB4EC;
}

.orderbatch .orders4{
	background: #EC5F53;
}

.orderbatch .ordersvip{
	background: #E2C067;
}

.margin5{
	margin: 2px;
	width: 24%;
}

.batchorder{
	font-size: 16px;
	background: #ffffff;
	box-shadow: 1px 2px 8px -3px #092147;
	border-bottom: 1px solid #DCDFE2;
	border-radius: 0 0 5px 5px;
    margin-bottom: 10px;
}

.batchorder .acceptedbatch{
	padding: 2px 10px;
	border-right: 1px solid #DCDFE2;
}

.acceptedbatchtitle{
	width:100%;
	color: #ffffff;
	padding: 8px 5px;
	border-radius: 5px 5px 0 0;
}

.acceptedbatchtitle1{
	background-color: #389885;
}

.acceptedbatchtitle2{
	background: #6C2B8D;
}

.acceptedbatchtitle3{
	background: #468CC4;
}

.acceptedbatchtitle4{
	background: #CF2E21;
}

.acceptedbatchtitlevip{
	background: #CC9813;
}

.rejectbatch{
	float: right;
	cursor: pointer;
	border: 1px solid #fff;
	padding: 0px 5px;
}

.ordersummary{
	border-bottom: 1px solid #DCDFE2;
	padding: 0px 5px;
	color: #8E939B;
	transition: background 0.8s;
	min-height: 40px;
	line-height: 40px;
}

.ordersummary:hover{
	background-color: #092147;
	color:#ffffff;
}

.ordersummary:hover .removeorder{
	color:#ffffff;
}

.ordersummary span{
	display:inline-block;
	cursor: pointer;
	min-width: 160px;
}

.ordersummary span.width100{
	width: 100%;
}

.assignrunnername {
	font-size: 14px;
	line-height: 1.5;
	color: #666666;
	display: inline-block;
	width: 100%;
	height: 30px;
	border:1px solid #324666;
    border-radius: 5px;
}

.col-sm-5.assign-runner-wrapper{
	width: 245px;
	max-width: 245px;
	flex: auto;
}

.col-sm-5.assign-runner-wrapper .assignrunnername{
	width:150px;
}

.catalog__search {
    -ms-flex-align: stretch;
    align-items: stretch;
    padding: 3rem
}

.catalog__modal,
.search-modal{
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(65, 74, 88, 0.7);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 1rem 0
}

.catalog__modal-body,
.search-modal__container {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    outline: 0;
    -ms-flex-preferred-size: 85%;
    flex-basis: 98%;
	max-height: 90vh;
	/*position: relative;*/
}

.acceptorderbtnwrapper{
	position: absolute;
	margin:auto;
	left:0;
	right: 0;
	width: 100%;
	bottom: 4rem;
	text-align: center;
}

.accept-order-btn {
	font-size: 14px;
	line-height: 1.5;
	color: #ffffff;
	background: #092147;
	height: 30px;
	border-radius: 5px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 15px;
	-webkit-transition: all 0.4s;
	-o-transition: all 0.4s;
	-moz-transition: all 0.4s;
	transition: all 0.4s;
}

.accept-order-btn:hover{
	background-color: #0d4097;
}

.search-modal__header {
    height: 65px;
    display: -ms-flexbox;
    -ms-flex-align: center;
    align-items: center;
    padding: 1.5rem 1rem 0;
	font-weight:bold;
	color:#333333;
	font-size: 18px;
	text-transform: capitalize;
	text-align:center;
}

.search-modal__header.detailheader{
	text-align: left;
	font-size: 15px;
}

.search-modal__body {
    background: white;
    height: calc(72vh - 150px);
    overflow: auto;
	padding: .5rem;
	color:#333333;
	width: 99%;
	position: relative;
}

.catalog__search__results {
    margin: 1rem 0;
}

.catalog__modal-body--dialog-footer,
.search-modal__footer {
    /*display: -ms-flexbox;
    display: flex;*/
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: end;
    /*justify-content: flex-end;*/
	padding: 1rem 1.5rem;
	display: flow-root;
}

.search-modal__footer .leftbutton{
	/*display: inline-flex;
    justify-content: flex-start;*/
    float: left;
    width: 100%;
    margin-bottom: 5px;
}

.search-modal__footer .rightbutton{
	/*display: flex;
	justify-content: flex-end;
    float: right;*/
    float: left;
    margin-bottom: 5px;    
}

.catalog__modal-body--dialog-footer {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: end;
    justify-content: flex-end;
	padding: 0.5rem 1.5rem;
	background: #eee;
}

.search-modal__footer .btn {
	margin-left: 0.5rem;
	color: #fff;
}

.catalog__modal-body--dialog {
    -ms-flex-preferred-size: 35%;
    flex-basis: 35%;
    color: #444
}

.catalog__index__copy {
	margin-bottom: 1.25rem;
	min-height: 50px;
}

.catalog__index__copy input {
    padding: 0.4em 1em;
    border-radius: 3px;
    outline: none;
    width: 100%;
    border: 1px solid #ebebeb
}

.catalog__index__copy input:hover {
    background: #f7f7f7
}

.catalog__index__copy input:focus {
    border: 1px solid #D1D1D1;
    border-radius: 3px;
    background: white
}

.closepopup {
	float: right;
	display:block;
	cursor: pointer;
	padding: 0 5px;
}

.greenbg{
	background: #399684;
	color:#ffffff;
	font-size: 15px;
}

.rightborder{
	border-right:1px solid #ffffff;
}

.nopadding{
	padding: 0;
	margin:0;
}

.zeromargin{
	margin: 0;
}

.osinfo{
	font-size: 14px;
	font-weight: 600;
	color: #3D424A;
	line-height: 25px;
}

.osrb{
	border-right: 1px solid #F1F2F3;
}

.oslb{
	border-left: 1px solid #F1F2F3;
}

.osbb{
	border-bottom: 1px solid #F1F2F3;
}

.bottomborder{
	border-bottom: 1px solid #E8E8EB;
}

.width20{
	width:20%;
}

/*.categoryheading{
	
}*/

.orderedcategory{
	text-align:left;
	padding: 3px 5px;
	font-weight: 600;
	font-size:15px;
}
.ordereditem{
	display: inline-block;
	margin-right: 5px;
	padding-right: 5px;
    font-size: 12px;
    width: 150px;
}
.ordereditem::after{
	content: ",";
}
.ordereditemwrapper{
	text-align:left;
	padding-bottom: 10px;
	margin-top:5px;
}
.ordereditemwrapper .ordereditem:last-child::after{
	content: "";
}
.ordereditem .itemname{
	display:inline-block;
	padding: 5px;
	background-color: #d2e8fb;
	border: 1px solid #9ec3e2;
	border-radius: 5px;
}
.ordereditem .itemname.stockout{
	background-color: #f8bfc7;
	border: 1px solid #E3465D;
}
.orderedrunner{
	margin: 0px auto;
	background-color: #EFF2F5;
	padding: 5px 5px;
	position: sticky;
	bottom: -15px;
	left: 0;
	right: 0;
	width: 100%;
}
.runnerheading{
	font-size: 15px;
	font-weight: 600;
}

.addmore-runner-btn {
	font-size: 14px;
	line-height: 1.5;
	color: #ffffff;
	background-color: #092147;
	border: 1px solid #092147;
	width: 75%;
	height: 30px;
	border-radius: 5px;
	display: inline-block;
	justify-content: center;
	align-items: center;
	padding: 0 15px;
	-webkit-transition: all 0.4s;
	-o-transition: all 0.4s;
	-moz-transition: all 0.4s;
	transition: all 0.4s;
}

.addmore-runner-btn:hover{
	background-color: #0d4097;
}

.addmore-runner-btn.go-btn{
    width: 50px;
    padding: 0px;
    margin-top: 0px;
}

.print-order-btn {
	font-size: 14px !important;
	line-height: 1.5;
	color: #ffffff;
	background: #E3465D;
	height: 30px;
	border-radius: 5px;
	/*display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;*/
	justify-content: center;
	align-items: center;
	padding: 0 15px !important;
	-webkit-transition: all 0.4s;
	-o-transition: all 0.4s;
	-moz-transition: all 0.4s;
    transition: all 0.4s;
    width: auto !important;
}

.print-order-btn:hover{
	background-color: #c01f38;
}

.print-order-btn:disabled{
	background-color: #e05f72;
	cursor: not-allowed;
}

.cancel-btn {
	font-size: 14px !important;
	line-height: 1.5;
	color: #ffffff;
	background: #B5B7BB;
	height: 30px;
	border-radius: 5px;
	/*display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;*/
	justify-content: center;
	align-items: center;
	padding: 0 15px !important;
	-webkit-transition: all 0.4s;
	-o-transition: all 0.4s;
	-moz-transition: all 0.4s;
    transition: all 0.4s;
    width: auto !important;
}

.cancel-btn:hover{
	background: #7f8185;
}

.font15{
	font-size: 15px;
}

.centertext{
	text-align: center;
}

.btn-selected {
	font-size: 14px;
	line-height: 1.5;
	color: #ffffff;
	background: #092147;
	height: 30px;
	border-radius: 0;
	display: inline-block;
	justify-content: center;
	align-items: center;
	padding: 0 15px;
	-webkit-transition: all 0.4s;
	-o-transition: all 0.4s;
	-moz-transition: all 0.4s;
	transition: all 0.4s;
}

.btn-selected:hover{
	color: #ffffff;
}

.btn-custom-bold{
	font-weight: 600;
}

.right-button{
	float: right;
}

.suggestedrunnertitle{
	font-weight: 600;
	font-size:15px;
}
.runnerdetail{
	display: inline;
}
.runnerdetail .orderrunner{
	display: inline-block;
	margin-right: 5px;
}
.runnername,
.addmore{
	display:inline-block;
	padding: 2px 5px;
	color:#65AEA1;
	font-weight: bold;
	border-radius: 5px;
	font-size: 14px;
}
.runnername.summaryname{
	color:#E3465D;
	cursor: default;
}
.runnername .pinkcolor{
	color:#E3465D;
	cursor: pointer;
	font-size: 15px;
}
.runnername.borderedrunner{
	border:1px solid #E3465D;
	text-align:center;
	margin-right: 5px;
	width: 100px;
}
.runnername.borderedrunner.priority{
	background: #CC9813 !important;
	border-color:#ffffff !important;
	color: #ffffff !important;
}
.runnername.norunner{
	color:#092147;
}
/*.addmore{
	color:#E3465D;
	font-size: 14px;
}*/
.releaserunnerwrapper{
	text-align: right;
}
.releaserunnerinput{
	width:200px;
	display: inline-block;
}
.center-box{
	margin:auto;
	text-align: center;
	font-size: 18px;
}
.table .thead-custom th{
	color: #363A43;
    background-color: #EFF2F5;
    border-color: #EFF2F5;
}
.headingbox{
	padding:0px 10px 0px 10px;
	border-right:2px solid #333333;
	float: left;
	line-height: 20px;
}
.headingbox.noborder{
	border-right:0;
}
.headingbox.nopadding{
	padding:0;
}
.viewitem{
	color: #3B9DFF;
}
.release{
	color: #3B9DFF;
	background: url("../images/release.png")center left 0px/15px auto no-repeat;
}

.errormsg{
    color: red;
    text-align: center;
}
.present{
	color: #B5B7BB;
	background: url("../images/error-disabled.png")center left 0px/15px auto no-repeat;
}

.absent{
	color: #F80404;
	background: url("../images/error-active.png")center left 0px/15px auto no-repeat;
}

input[type='number'].customepassword {
    -webkit-text-security: disc;
}

.form-control{
    height: 4rem;
    font-size: 1.5rem;
}
.btn{
    font-size:1.5rem;
    padding: .5rem 1.2rem;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {

    .page-content .seatcodewrapper{
        max-width: 630px;
    }

    .seatcodewrapper input[type='text'], .seatcodewrapper input[type='number']{
        background:url(../images/input-qr-code.svg) no-repeat 20px 20px;
        padding: 0px 5px 3px 55px;
        height: 4.40rem;
        font-size: 30px;
    }

    .seatcodewrapper input[type='button'], .seatcodewrapper button[type='submit']{
        height: 4.6rem;
        font-size: 30px;
    }

    .scannerwrapper{
        max-width: 700px;
    }
    .loadingwrapper {
        /*margin: 20em auto;*/
        margin: 0 auto;
    }

    .searchfilter .searchbox input[type='text'],
    .searchfilter .searchbox input[type='number']{
        font-size: 25px;
        height: 48px;
    }

    .searchfilter .searchbox.loggedin{
        width: 91.5%;
    }

    .titlewrapper h3{
        font-size: 1.70rem;
    }

    .titlewrapper h3 span{
        font-size: 1.50rem;
    }

    .product-item-thumb-wrapper .product-thumb{
        height: 120px;
    }

    .products-list .product-title-english,
    .cart-products-list .product-title-english{
        font-size: 25px;
    }

    .products-list .product-title-hindi,
    .cart-products-list .product-title-hindi{
        font-size: 22px;
    }

    .product-listing-wrapper .products-list li .product-qty-add-wrapper,
    .local-cart-container .cart-products-list li .product-qty-add-wrapper{
        font-size: 25px;
        height: 45px;
        min-width: 125px;
        border-radius: 20px;
        padding: 2px 10px;
        margin-top: 6px;
    }

    .iconincrease{
        width: 20px;
        height: 20px;
    }

    .icondecrease{
        width: 30px;
        height: 18px;
    }

    ._387x7{
        bottom: 8rem;
    }

    ._3LVcW{
        width: 170px;
        height: 50px;
        font-size: 1.8rem;
    }

    .icon-menu{
        width: 30px;
        height: 30px;
        background-position: 1% 5px;
    }

    .product-listing-wrapper .product-qty-plus-minus-wrapper,
    .local-cart-container .product-qty-plus-minus-wrapper,
    .product-listing-wrapper .outofstockdiv,
    .local-cart-container .outofstockdiv,
    .local-cart-container .removeitem{
        width: 120px;
        border-radius: 20px;
        height:40px;
    }

    .product-qty-container{
        font-size: 25px;
        min-width: 50px;
    }

    .product-qty-add{
        margin-top: 8px;
    }

    .product-qty-plus{
        padding: 0;
    }

    .viewcarttitle{
        font-size: 28px;
    }

    .viewcartsubtitle{
        font-size: 20px;
    }

    .orloseattitle{
        font-size: 35px;
    }

    .confirmseatbox button{
        font-size: 35px;
        max-width: 220px;
    }

    .appwidth50{
        flex: 40%;
        max-width: 50%;
    }

    .empty-cart-button{
        font-size: 25px;
    }

    .empty-cart-button .clear{
        width: 50px;
        height: 40px;
        background:url("../images/delete.svg")center left 12px/25px auto no-repeat;
    }

    .closecartpopup{
        background:url("../images/closecart.svg")center left 10px/25px auto no-repeat;
        width: 25px;
        height: 25px;
        width: 40px;
        margin-top: 25px;
    }

    ul.footer-cart-left-links li, ul.footer-cart-right-links li{
        font-size: 20px;
    }

    ul.footer-cart-left-links li .itemlist{
        font-size: 16px;
    }

    ul.footer-cart-right-links li .icon-cart{
        background:url("../images/shopping-bag.svg")center left 0px/25px auto no-repeat;
        height: 30px;
    }

    .mobile-menu{
        width: 50%;
        right:-500px;
    }

    .mobile-menu-wrapper ul li .categorytitle_old{
        font-size: 20px;
    }
    .mobile-menu-wrapper ul li .categoryquantity{
        font-size: 20px;
    }

    .opened .view-cart-wrapper{
        min-height: 28rem;
    }

    .notfoundsubtitle{
        font-size: 30px;
        margin-top: 1.5rem;
    }

    .placeneworderbtn button{
        font-size: 25px;
    }
}

@media only screen and (min-width: 411px) and (max-width: 823px) {
    .searchfilter .searchbox.loggedin {
        width: 91.8%;
    }

    .mobile-menu{
        width: 70%;
        right:-60rem;
    }

    .mobile-menu-wrapper ul li .categorytitle_old{
        font-size: 16px;
    }
    .mobile-menu-wrapper ul li .categoryquantity{
        font-size: 16px;
    }

    .loadingwrapper {
        /*margin: 10em auto;*/
        margin: 0 auto;
    }

    .notfoundsubtitle {
        font-size: 24px;
        margin-top: 1rem;
    }
}

@media only screen and (min-width: 360px) and (max-width: 640px) {
    .searchfilter.loggedin{
        width: 85%;
    }
    .account-logout-button {
        margin: 0.1rem 0.4rem 0.1rem 0.4rem;
    }
    .searchbox .runnerinputwrapper{
        width: 70%;
    }
    .runneridbox{
        padding: 2px;
        width: 40px;
    }
    .local-cart-container.selecttable.engagedrunner .table{
        font-size: 22px;
    }
    .runnernamebox{
        font-size: 16px;
    }
    /*.local-cart-container.selecttable.engagedrunner .table .runnerleftbox{
        width: 140px;
    }
    .local-cart-container.selecttable.engagedrunner .table .runnerrightbox{
        width: 130px;
    }*/
    .engagedstatus{
        font-size: 14px;
    }
    .searchfilter .searchbox input[type='text'], .searchfilter .searchbox input[type='number']{
        width: 85%;
    }
    .manageitem .managecategory{
        width: 50%;
    }
    .form-control.orderby{
        width: 40%;
    }
}

.searchbox .runnerinputwrapper.kote{
    width: 100%;
    border-bottom:5px solid #e8e8e8;
}

.linkstyle{
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

fieldset.options{
    border:1px solid #007bff !important;
    padding:5px;
}

legend.optiontitle{
    font-size:16px;
    font-weight:bold;
    color:#000000;
    display: inline-block;
    width: unset;
    text-align: left;
    text-decoration: unset;
}

.googlecontronbtn .savebutton{
    width: 45% !important;
}

.MuiDrawer-root.MuiDrawer-modal{
    z-index: 9999 !important;
}

@media only screen and (min-width: 546px) and (max-width: 601px) {
    .searchbox .runnerinputwrapper{
        width: 59%;
    }
    /*.searchbox .runnerinputwrapper.kote{
        width: 50%;
    }*/
}

@media only screen and (min-width: 320px) and (max-width: 568px) {
    .searchfilter .searchbox.loggedin {
        width: 89%;
    }
    .account-logout-button{
        margin: 0.2rem .3rem 0.2rem .3rem;
    }
    .logoutbtn img{
        width: 25px;
    }
    .runnerinputwrapper{
        width: 65%;
    }
    .searchfilter .searchbox input[type='text'], .searchfilter .searchbox input[type='number']{
        width: 80%;
    }
}