@media only screen and (min-width: 360px) and (max-width: 768px) {
    .categorytitle {
        font-size: 1.8rem;
        line-height: 1;
        padding: 1.2rem 0px;
    }
    .smallpreviewchildbox {
        /*margin-right: 1rem;*/
        margin-right: 0rem;
    }
    .previewbox2 {
        border-radius: 0.4rem;
        overflow: hidden;
    }
    .largebox1 {
        border-radius: 0.4rem;
        overflow: hidden;
    }
    .HBfan {
        min-height: 100vh;
    }
    .itemtitle {
        font-size: 1.8rem;
    }
    .itemtitle.demo,
    .itemtitle.demohindi{
        font-size: 1.6rem;
    }
    .voteavg {
        font-size: 1.1rem;
    }
    .discount {
        font-size: 1.1rem;
    }
    .qtyadd {
        width: 8rem;
        height: 2.8rem;
    }
    .qtytitle {
        font-size: 1.4rem;
    }
    .iconmargin {
        width: 1.5rem;
        height: 1.5rem;
    }
    .itemsummary {
        font-size: 1.2rem;
        font-weight: 300;
        max-width: 100%;
        margin: 0px 0px 0px 1.8rem;
    }
    .qtychildin {
        width: 8rem;
        height: 2.8rem;
    }
    .iNGntN {
        width: 1.5rem;
        height: 1.5rem;
    }
    .kwfqFr {
        position: relative;
        overflow: hidden;
    }
    .kwfqFr:not(:active)::after {
        animation: 1s ease-out 0s 1 normal none running bXTtfc;
    }
    .kwfqFr::after {
        visibility: hidden;
    }
    .kwfqFr::after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0);
        width: 50rem;
        height: 50rem;
        background-color: rgb(255, 255, 255);
        border-radius: 100%;
        opacity: 0.6;
    }
    /*.cartwrapper {
        margin: 0px 2.4rem;
    }*/
    .cartheading{
        margin: 0px 2.4rem;
    }
    .cartheadingtitle {
        font-size: 1.5rem;
        font-weight: 500;
    }
    .kSIUYJ {
        height: 36rem;
        margin: 0px 2.4rem;
    }
    .eNNUsK {
        align-items: flex-start;
    }
    .btxdAi {
        font-size: 1.2rem;
        margin: 0px 0px 0px 2.3rem;
    }
    .iaSZFy {
        font-size: 1.4rem;
    }
    .iitULE {
        margin-top: 0.4rem;
    }
    .foGWxa {
        /*margin-right: 1rem;*/
        margin-right: 0px;
    }
    .kJQFyq {
        height: 4.1rem;
        width: 100%;
        line-height: 1.5rem;
        font-size: 1.1rem;
        border-radius: 0px;
        left: 0px;
        transform: translate(0px, -6.5rem);
    }
    .kdnrAZ {
        font-size: 1.2rem;
        line-height: 1.6rem;
    }
    .hwKGtB {
        height: 5rem;
        width: 100%;
        line-height: 1.5rem;
        font-size: 1.1rem;
        border-radius: 0px;
        left: 0px;
        transform: translate(0px, -6.5rem);
    }
    .hyQzCb {
        width: 100vw;
        /*min-height: 95vh;
        margin: calc(5vh) auto auto;
        */
        min-height: 85vh;
        margin: calc(15vh) auto auto;
        transition: all 0.25s ease 0s, visibility 0ms ease 0s;
        border-radius: 0.6rem 0.6rem 0px 0px;
    }
    .eggzWm {
        width: calc(100% + 0.5rem);
        margin: 0px;
    }
    .oRYSe {
        min-height: 8rem;
    }
    .idQvOD {
        width: 100%;
        padding: 0px;
        margin: 0px;
    }
    .ipHtgH {
        width: 7.2rem;
        height: 4.4rem;
    }
    .fTsfFl {
        font-size: 1.2rem;
    }
    .TZpZK {
        width: 0.8rem;
        height: 0.8rem;
    }
    .bXdRxo {
        position: relative;
        overflow: hidden;
    }
    .iBlUrl {
        display: block;
        min-height: 5vh;
        -webkit-box-flex: 1;
        flex-grow: 1;
        width: 100%;
    }
    .gzwZLJ {
        width: 100vw;
        margin: 0px auto auto;
        transition: all 0.25s ease 0s, visibility 0ms ease 0s;
        border-radius: 0.6rem 0.6rem 0px 0px;
        min-height: 0px;
    }
    .HTAAf {
        padding: 0px 0px 10px;
        max-width: 100%;
    }
    .dKmxez {
        padding: 0px 0px 10px;
        max-width: 100%;
    }
    .fgjele {
        display: flex;
        flex-direction: column;
    }
    .hSfokC {
        width: 100vw;
        min-height: 65vh;
        margin: calc(35vh) auto auto;
        transition: all 0.25s ease 0s, visibility 0ms ease 0s;
        border-radius: 0.6rem 0.6rem 0px 0px;
    }
    .mobile-menu{
        width: 70%;
    }
}
@media (max-width: 480px){
    .cartwrapper {
        max-width: 42.4rem;
    }
}
@media only screen and (min-width: 601px) and (max-width: 610px) {
    .filtertitle2 {
        width: 40%;
    }
    .newfiltertoggle2 {
        width: 60%;
    }
    .newfiltertoggle .newfilter{
        font-size:1.5rem;
    }
    .veg,
    .nonveg,
    .veg.containegg{
        width: 1.8rem;
        height: 1.8rem;
    }
    .newfiltertoggle .newfilter{
        padding: .8rem 1.5rem;
    }
    .mobile-menu{
        right: -70rem;
    }
    .itemtitle {
        font-size: 1.8rem;
    }
    .smallimagebox{
        width: 12rem;
        height: 12rem;
    }
    .qtyadd,
    .qtychildin {
        width: 11rem;
        height: 3.7rem;
    }
    .qtytitle{
        font-size: 2rem;
    }
    .iconmargin{
        width: 2rem;
        height: 2rem;
    }
    .listicon{
        width: 25px;
        height: 25px;
    }
    /*.menubtn{
        width: 12rem;
        height: 3.8rem;
    }
    .menubtn.caption{
        width: 20rem;
    }
    .fa-shopping-cart, .fa-cart-plus{
        top: -.2rem;
        font-size:2.5rem;
    }
    .menulines{
        width: 2.1rem;
        height: 2rem;
    }
    .lines{
        height: 0.3rem;
    }
    .lines:nth-child(1){
        top: 1px;
    }
    .lines:nth-child(2){
        top: .8rem;
    }
    .lines:nth-child(3){
        top: 1.5rem;
    }
    .menutitle{
        font-size: 2.2rem;
    }*/
}
@media (hover: none){
    :active, :focus {
        outline: 0!important;
    }
}