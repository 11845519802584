@import url('fonts.css');

*, :after, :before {
    box-sizing: inherit;
    -webkit-tap-highlight-color: transparent;
}
body, button, input, input::placeholder {
    font-family: "VarelaRound-Regular",Helvetica,sans-serif;
}
body, html {
    margin: 0;
    padding: 0;
    font-size: 62.5%;
    font-weight: 300;
    box-sizing: border-box;
}
*:focus {
    outline: none;
}
input {
	outline: none;
	border: none;
}
button {
	outline: none !important;
	border: none;
	background: transparent;
}
button:hover {
	cursor: pointer;
}
div, p, span, .MuiFormLabel-root {
    font-size: 1.6rem;
}
.fSctdt {
    position: relative;
}
.mainwrapper {
    /*padding: 0px 1rem;*/
    padding: 0px;
    margin-top: -0.1rem;
    background-color: #ffffff;
}
.mainwrapper.cartshowing{
    margin-bottom:15rem;
}
.filterwrapper {
    width: 100%;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    background: #FBFBFB;
    box-shadow: rgb(0 0 0 / 20%) 0px 1px 0px 0px;
    padding: 1.5rem 0px;
    height: 6.3rem;
    position: sticky;
    /*top: 56px;*/
    top: -1px;
    z-index: 3;
}
.filterwrapper.withsmallads{
    top: 175px;
}
.vegnonvegtogglewrapper {
    display: flex;
    background: #ffffff;
}
.firsttogglebox {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    margin-left: 0px;
}
.secondtogglebox {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    margin-left: 20px;
}
.togglebutton{
    position: relative;
    display: inline-block;
    width: 3.8rem;
    height: 2.6rem;
    appearance: none;
    outline: none;
}
.togglebutton{
    opacity: 0;
    width: 0px;
    height: 0px;
}
.cntegg {
    line-height: 1.2;
    margin: 0px 0px 0px 1rem;
    font-size: 1.2rem;
    font-weight: 500;
    color: #363636;
}
.inact{
    position: absolute;
    cursor: pointer;
    inset: 0px;
    appearance: none;
    display: inline-flex;
    vertical-align: middle;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    margin-top: 0px;
    margin-left: 0px;
    background-color: #cccccc;
    transition: background-color 0.15s ease 0s, opacity 0.15s ease 0s;
    border-radius: 2.6rem;
    outline: none;
}
.inact::before {
    position: absolute;
    content: "";
    height: 2.2rem;
    width: 2.2rem;
    left: 0.2rem;
    background-color: #ffffff;
    margin-top: 0px;
    margin-left: 0px;
    outline: none;
    border-radius: 50%;
    transition: transform 0.15s ease 0s, opacity 0.15s ease 0s;
    transform: translateX(0px);
    border: 0.5px solid rgba(0, 0, 0, 0.04);
    box-shadow: rgb(28 28 28 / 10%) 0px 0.1rem 0.4rem;
}
.acttog {
    position: absolute;
    cursor: pointer;
    inset: 0px;
    appearance: none;
    display: inline-flex;
    vertical-align: middle;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    margin-top: 0px;
    margin-left: 0px;
    background-color: #50b547;
    transition: background-color 0.15s ease 0s, opacity 0.15s ease 0s;
    border-radius: 2.6rem;
    outline: none;
}
.acttog.containegg{
    background-color: #f3c117;
}
.acttog::before {
    position: absolute;
    content: "";
    height: 2.2rem;
    width: 2.2rem;
    left: 0.2rem;
    background-color: white;
    margin-top: 0px;
    margin-left: 0px;
    outline: none;
    border-radius: 50%;
    transition: transform 0.15s ease 0s, opacity 0.15s ease 0s;
    transform: translateX(1.1rem);
    border: 0.5px solid rgba(0, 0, 0, 0.04);
    box-shadow: rgb(28 28 28 / 10%) 0px 0.2rem 0.4rem;
}
.tglspan {
    position: relative;
    display: inline-block;
    width: 3.8rem;
    height: 2.6rem;
    appearance: none;
    outline: none;
}
.vegonlytitle {
    line-height: 1.2;
    margin: 0px 0px 0px 1rem;
    font-size: 1.2rem;
    font-weight: 500;
    color: #363636;
}
.idlesearchbx {
    height: 3.6rem;
    width: 3.6rem;
    border-radius: 50%;
    padding: 0.9rem;
    margin: 0px 0px 0px 1.2rem;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    border: 1px solid #f8f8f8;
    background-color: #ffffff;
}
.searwrapper {
    width: 100%;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    background: #ffffff;
    /*padding: 1.5rem 0px 0px;*/
    padding: 0rem 0px 0px;
    height: 6.3rem;
    position: sticky;
    top: 56px;
    z-index: 2;
}
.searchbx {
    position: relative;
    width: 100%;
}
.firstfindchild {
    position: relative;
    margin: 0px;
    height: 4.8rem;
}
.findchildsub {
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    position: absolute;
    top: 15px;
    left: 10px;
}
.iconblk {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    cursor: inherit;
}
.iconsc {
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    width: 17px;
    height: 17px;
}
.iconsc.newcross{
    width: 30px;
    height: 30px;
}
.searchinput {
    /*padding: 1.3rem 3.5rem;*/
    padding: .2rem 1.5rem .2rem 3.5rem;
    height: 4.8rem;
    background: #ffffff;
    /*border: 0.1rem solid rgb(207, 207, 207);*/
    border: 0.1rem solid #1A73E8;
    font-size: 18px;
    width: 100%;
    outline: none;
    box-sizing: border-box;
    border-radius: 0.6rem;
    color: #1c1c1c;
}
.closefindbox {
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    position: absolute;
    /*top: 15px;*/
    top: 7px;
    right: 10px;
    background: #ff0000;
    color:#ffffff;
}
.closefindboxnew {
    position: absolute;
    right: 10px;
    line-height: 1;
    position: absolute;
    background: #ff0000;
    color:#ffffff;
}
.closefindchild {
    cursor: pointer;
    width: 3.5rem;
    height: 3.5rem;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    border-radius: 50%;
    /*margin-top: -0.8rem;*/
}
.lhYVDQ {
    position: absolute;
    pointer-events: none;
    transition: all 0.2s ease 0s;
    color: #b5b5b5;
    top: 13px;
    left: 37px;
    font-size: 18px;
    background: #ffffff;
    padding: 0px;
    max-width: 90%;
    max-height: 2.2rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.parrentmenubox {
    position: relative;
}
.categorytitle {
    font-size: 2.4rem;
    line-height: 1.2;
    /*color: #ffffff;*/
    color: #092147;
    margin: 0px;
    margin-block-end: 0px;
    position: sticky;
    top: 104px;
    padding: 1.5rem 0px;
    /*background: #ed5a6b;*/
    background: #9DDAED;
    font-weight: 600;
    z-index: 1;
    /*display: flex;
    justify-content: space-between;*/
    text-align: center;
}
.categorytitle.eventmenutitle{
    z-index: 2;
    top: 62px;
    color: #ffffff;
    background:#092147;
    border: #092147;
}
.categorytitle.withsmallads{
    top: 238px;
}
.categorytitle.withouteventmenu{
    top: 62px;
}
.categorytitlesep {
    width: 100%;
    height: 7px;
    /*background: rgb(255, 255, 255);*/
    position: sticky;
    /*top: 154px;*/
    top: 60px;
    margin-top: -2px;
    z-index: 1;
    padding-top: 1.5rem;
}
.productblock {
    margin-bottom: 2rem;
    padding:0px 1rem;
}
.smallpreviewbox {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #e8e5e5;
    padding-bottom: 3.5rem;
}
.smallpreviewchildbox {
    position: relative;
    margin-right: 2rem;
    margin-bottom: 0px;
    flex-shrink: 0;
    z-index: 0;
}
.previewbox2 {
    border-radius: 0.8rem;
    visibility: visible;
    /*transition: all 0.12s ease 0s;*/
}
.smallimagebox {
    position: relative;
    max-width: 100%;
    width: 8.5rem;
    height: 8.5rem;
    overflow: hidden;
}
.smallimageboxlarge {
    position: relative;
    max-width: 100%;
    width: 15rem;
    height: 15rem;
    overflow: hidden;
}
.imagesepbox {
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    background: linear-gradient(to right, rgb(248, 248, 248) 0%, rgb(255, 255, 255) 10%, rgb(248, 248, 248) 40%, rgb(248, 248, 248) 100%) no-repeat rgb(248, 248, 248);
    opacity: 0;
    transition: opacity 0.25s ease-out 0s;
    will-change: opacity;
    border-radius: inherit;
    animation: 1.5s linear 0s infinite normal forwards running jCOzbu;
}
.menupreview {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: none;
    opacity: 1;
    will-change: transform, opacity;
    border-radius: inherit;
    filter: unset;
    /*transition: opacity 0.25s ease 0s, transform 0.25s ease 0s;*/
}
.largebox2 {
    position: relative;
    max-width: 100%;
    width: 0px;
    height: auto;
    overflow: hidden;
}
.largebox1 {
    border-radius: 0.8rem;
    visibility: hidden;
    /*transition: all 0.12s ease 0s;*/
    height: 0px;
    width: 0px;
}
.lrgclr {
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    background: linear-gradient(to right, rgb(248, 248, 248) 0%, rgb(255, 255, 255) 10%, rgb(248, 248, 248) 40%, rgb(248, 248, 248) 100%) no-repeat rgb(248, 248, 248);
    opacity: 1;
    transition: opacity 0.25s ease-out 0s;
    will-change: opacity;
    border-radius: inherit;
    animation: 1.5s linear 0s infinite normal forwards running jCOzbu;
}
.bKaJwg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scale(0.9);
    opacity: 0;
    will-change: transform, opacity;
    border-radius: inherit;
    filter: unset;
    transition: opacity 0.25s ease 0s, transform 0.25s ease 0s;
}
.itemtypesymbol {
    position: absolute;
    top: 0.4rem;
    /*top: 0.6rem;
    left: 0.6rem;*/
}
.itemtypesymbol_leftimage {
    position: absolute;
    top: 0.6rem;
    right: 0.6rem;
}
.nonveg {
    width: 1.3rem;
    height: 1.3rem;
    border: 1px solid #ed5a6b;
    border-radius: 2px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    background: #ffffff;
    flex-shrink: 0;
}
.veg {
    width: 1.3rem;
    height: 1.3rem;
    border: 1px solid #50b547;
    border-radius: 2px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    background: #ffffff;
    flex-shrink: 0;
}
.veg.containegg{
    border: 1px solid #f3c117;
}
.eEOGnT {
    margin: 0.6rem 1rem 0px 0px;
    flex-shrink: 0;
}
.roundnonvegtype {
    width: 0.7rem;
    height: 0.7rem;
    background: #ed5a6b;
    border-radius: 50%;
}
.roundvegtype {
    width: 0.7rem;
    height: 0.7rem;
    background: #50b547;
    border-radius: 50%;
}
.roundvegtype.containegg{
    background: #f3c117;
}
.iteminfo {
    width: 100%;
    min-width: 1%;
}
.iteminfoch {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
}
.iteminfochin {
    display: flex;
    flex-direction: column;
    max-width: 75%;
    position: relative;
}
.itemtitle {
    font-size: 1.8rem;
    font-weight: 600;
    color: rgb(28, 28, 28);
    line-height: 150%;
    margin: 0px;
    margin-left:2.3rem;
}
.itemtitle.canceleditem{
    text-decoration:line-through;
}
.itemtitle.demohindi{
    font-weight:normal;
}
.product-status-wrapper .product-status.red{
    color: #ff0000;
}
.product-status-wrapper .product-status.green{
    color: #008000;
}
.product-status-wrapper .runnername{
    color: #092147;
    margin-top: 5px;
    font-weight: 600;
}
.itemtagbox {
    display: flex;
    flex-wrap: wrap;
    margin: 0.5rem 0px 0px 1.8rem;
}
.itemtag {
    margin-right: 0.5rem;
    margin-bottom: 0.2rem;
}
.orangetagtitle {
    height: max-content;
    width: max-content;
    background: #f4a266;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    border-radius: 4px;
    color: #ffffff;
    font-size: 1rem;
    border: none;
    padding: 0.2rem 0.5rem;
}
.bluetagtitle {
    height: max-content;
    width: max-content;
    background: #539cee;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    border-radius: 4px;
    color: #ffffff;
    font-size: 1rem;
    border: none;
    padding: 0.2rem 0.5rem;
}
.votebox {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}
.voteboxchild {
    display: flex;
}
.gUXgQo {
    margin-right: 0.2rem;
}
.listicon {
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    width: 15px;
    height: 15px;
}
.voteavg {
    margin-left: 0.7rem;
    font-size: 1.4rem;
    color: #9c9c9c;
    margin-top: 0.2rem;
}
.pricebox {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}
.voteboxmargin {
    margin: 0.3rem 0px;
}
.priceboxmargin {
    margin: 0.5rem 0px 0px 1.8rem;
}
.orgprice {
    font-size: 1.3rem;
    color: #9c9c9c;
    margin-right: 0.5rem;
    text-decoration: line-through;
}
.offerprice {
    font-size: 1.4rem;
    color: #1c1c1c;
}
.discount {
    font-size: 1.4rem;
    font-weight: 500;
    color: #f57082;
    margin-left: 0.5rem;
}
.qtychild {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    position: relative;
}
.qtychild.leftimage{
    width: unset;
    position: absolute;
    bottom: 5rem;
    right: 0;
}
.qtychild.withcustomization{
    position: absolute;
    bottom: -3rem;
    /*right:0.6rem;*/
    z-index:9;
}
.qtychild.withoutcustomization{
    position: absolute;
    bottom: -1.6rem;
    /*right:0.6rem;*/
    z-index:9;
}
.qtychild.withcustomization.selfserviceable{
   bottom: -2.5rem;
   text-align: center;
}
.qtychild.withoutcustomization.selfserviceable{
   bottom: -2.5rem;
   text-align: center;
}
.foGWxa .qtychild{
    width: auto;
}
.foGWxa .qtychild.withcustomization,
.foGWxa .qtychild.withoutcustomization{
    right:0.2rem;
}
.qtyadd {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 8.2rem;
    height: 3.2rem;
    border: 1px solid #ef5b6c;
    border-radius: 0.6rem;
    overflow: hidden;
    cursor: pointer;
    user-select: none;
    background: #FFF6F7;
    text-transform: uppercase;
}
.qtytitle {
    color: #ED5A6B;
    font-size: 1.4rem;
    margin: 0px 0.5rem;
    font-weight: 600;
}
.iconmargin {
    margin-top: 0rem;
}
.customizabletitle {
    line-height: 1.2rem;
    display: inherit;
    font-size: 1rem;
    margin-top: 4px;
    color: #f4a266;
}
.itemsummary {
    margin: 0.5rem 0px 0px 1.8rem;
    font-size: 1.4rem;
    color: #4f4f4f;
    max-width: 75%;
    overflow-wrap: break-word;
}
.categoryfilterbox {
    width: max-content;
}
.menubtnwitoutcart {
    position: fixed;
    right: 1.8rem;
    bottom: 0.7rem;
    box-shadow: rgb(125 125 125 / 20%) 0px 2px 8px;
    transition: bottom 300ms ease 0s;
    border-radius: 6.6rem;
    z-index: 9;
}
.menubtnwitoutcart.caption {
    bottom: 5rem;
}
.menubtnwitoutcart.orderstatus{
    right: 12rem;
}
.menubtnwitoutcartstatus {
    position: fixed;
    bottom: 0rem;
    transition: bottom 300ms ease 0s;
    z-index: 9;
    left: 50%;
    transform: translate(-50%, -20%);
    margin: 0 auto;    
}
.menubtnwitoutcartstatus .menubtn{
    box-shadow: rgb(125 125 125 / 20%) 0px 2px 8px;
}
.menubtncheckout {
    position: fixed;
    left: 1.8rem;
    transition: bottom 300ms ease 0s;
    z-index: 9;
    margin: 0 auto;    
}
.menubtncheckout.withoutcart{
    bottom: 0.7rem;
}
.menubtnwitoutcartstatus .menubtn{
    box-shadow: rgb(125 125 125 / 20%) 0px 2px 8px;
}
.menubtnwithcart {
    position: fixed;
    right: 1.8rem;
    bottom: 11.1rem;
    /*bottom: 7.1rem;*/
    box-shadow: rgb(125 125 125 / 20%) 0px 2px 8px;
    transition: bottom 300ms ease 0s;
    border-radius: 6.6rem;
    z-index: 9;
}
.menubtnwithcart.caption {
    bottom: 16.1rem;
}
.menubtnwithcart.orderstatus{
    right: 12rem;
}
.menubtnwitcartstatus {
    position: fixed;
    bottom: 10.1rem;
    transition: bottom 300ms ease 0s;
    z-index: 9;
    left: 50%;
    transform: translate(-50%, -20%);
    margin: 0 auto;    
}
.menubtnwitcartstatus .menubtn{
    box-shadow: rgb(125 125 125 / 20%) 0px 2px 8px;
}
.menubtncheckout.withcart{
    bottom: 11.1rem;
}
.filterbox {
    position: absolute;
    right: 0px;
}
.hiddenmenu {
    position: absolute;
    bottom: 1rem;
    right: 0px;
    max-width: 25rem;
    max-height: 35rem;
    overflow-y: auto;
    border-radius: 0.4rem;
    background: #ffffff;
    box-shadow: rgb(28 28 28 / 10%) 0px 2px 8px;
    z-index: 9;
    opacity: 0;
    transition: opacity 0s ease 0s, transform 100ms ease 0s;
    transform: translateY(-1rem);
}
.openedmenu {
    position: absolute;
    bottom: 1rem;
    right: 0px;
    max-width: 50rem;
    max-height: 38rem;
    overflow-y: auto;
    border-radius: 0.4rem;
    background: #ffffff;
    box-shadow: rgb(28 28 28 / 10%) 0px 2px 8px;
    z-index: 9;
    opacity: 1;
    transition: opacity 0s ease 0s, transform 100ms ease 0s;
    transform: translateY(0px);
}
.fCYLai,
.singlecategorybox {
    display: flex;
    -webkit-box-align: center;
    /*align-items: center;*/
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 100%;
    min-width: 22.5rem;
    padding: 1rem;
    cursor: pointer;
}
.fCYLai > h6 {
    font-weight: 500;
}
.singlecategorytitle {
    font-weight: 400;
    margin: 0px;
    font-size: 1.5rem;
    line-height: 1.5;
    color: rgb(28, 28, 28);
    max-width: 80%;
    /*text-overflow: ellipsis;
    white-space: nowrap;*/
    overflow: hidden;
}
.singlecategoryqty {
    font-weight: 400;
    margin: 0px;
    font-size: 1.5rem;
    line-height: 1.5;
    color: #696969;
}
.menubtn {
    width: 8.6rem;
    height: 3.6rem;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 6.6rem;
    background:#363636;
    border: #363636;
    padding: 0px;
    cursor: pointer;
}
.menubtn.caption{
    width: 13rem;
    background: rgb(237, 90, 107);
    border: 1px solid rgb(237, 90, 107);    
}
.menulines {
    width: 1.1rem;
    height: 1rem;
    margin-right: 0.5rem;
    position: relative;
    transform: rotate(0deg);
    transition: transform 0.15s ease-in-out 0s;
    cursor: pointer;
}
.lines:nth-child(1) {
    top: 0px;
}
.lines:nth-child(2) {
    top: 0.4rem;
}
.lines:nth-child(3) {
    top: 0.8rem;
}
.fa-shopping-cart,
.fa-cart-plus {
    top: -.5rem;
    left: -.4rem;
    color: rgb(255, 255, 255);
    position: absolute;
    font-size: 1.8rem;
    line-height: 2rem;
}
.menulines > span {
    background: rgb(255, 255, 255);
}
.lines {
    transform-origin: left center;
    display: block;
    position: absolute;
    cursor: pointer;
    height: 0.2rem;
    width: 100%;
    border-radius: 0px;
    opacity: 1;
    left: 0px;
    transform: rotate(0deg);
    transition: transform 0.15s ease-in-out 0s, all 0.15s linear 0s;
}
.menutitle {
    display: inline-block;
    vertical-align: middle;
    transition: transform 0.4s ease 0s;
    font-size: 1.5rem;
    line-height: 2rem;
    color: rgb(255, 255, 255);
    font-weight: 500;
}
.menuclose {
    width: 1.1rem;
    height: 1rem;
    margin-right: 0.5rem;
    position: relative;
    transform: rotate(0deg);
    transition: transform 0.15s ease-in-out 0s;
    cursor: pointer;
}
.menuclose > span:nth-child(1) {
    transform: rotate(45deg);
}
.menuclose > span:nth-child(2) {
    width: 0%;
    opacity: 0;
}
.menuclose > span:nth-child(3) {
    transform: rotate(-45deg);
}
.menuclose > span {
    background: rgb(255, 255, 255);
}
.qtychildin {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 8.2rem;
    height: 3.2rem;
    border: 1px solid #ED5A6B;
    border-radius: 0.6rem;
    overflow: hidden;
    cursor: initial;
    user-select: none;
    background: #ED5A6B;
    position: relative;
}
/*.huikLB {
    height: 100%;
}*/
.cMipmx {
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.hTzRFw {
    position: relative;
    width: 100%;
    -webkit-box-flex: 0;
    flex-grow: 0;
    flex-shrink: 0;
    max-width: 31.6667%;
    padding: 0px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
}
.iNGntN {
    margin-top: 0.2rem;
}
.iQCkqv {
    position: relative;
    width: 100%;
    -webkit-box-flex: 0;
    flex-grow: 0;
    flex-shrink: 0;
    max-width: 36.6667%;
    padding: 0px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    background: #ED5A6B;
    cursor: initial;
    height: 99%;
}
.iTfwVs {
    position: fixed;
    width: 100%;
    bottom: 0px;
    /*height: 6.5rem;
    justify-content: center;*/
    height: 0rem;
    background: rgb(255, 255, 255);
    box-shadow: rgb(125 125 125 / 20%) 0px 2px 8px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    transform: translate(0px, 0px);
    visibility: visible;
    /*transition: all 0.2s ease 0s;*/
    border-radius: 2rem 2rem 0 0;
}
.cYGtmD {
    height: 55rem;
}
.eidlGK {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    cursor: pointer;
    min-width: 13rem;
    user-select: none;
}
.jiJXCU {
    margin-bottom: 0.2rem;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}
.jDKpvz {
    line-height: 1.2;
    font-weight: 400;
    margin: 0px;
    /*color: #696969;*/
    font-size: 1.2rem;
}
.bKApuF {
    margin-left: 0.5rem;
    transform: rotateZ(0deg);
    transition: transform 0.2s ease 0s;
}
.dTCpVC {
    margin-left: 0.5rem;
    transform: rotateZ(180deg);
    transition: transform 0.2s ease 0s;
}
.fQZfgq {
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    width: 12px;
    height: 12px;
}
.kguiiK {
    display: flex;
    -webkit-box-align: baseline;
    align-items: baseline;
    flex-wrap: wrap;
}
.jOOYEx {
    font-size: 1.5rem;
    color: #9c9c9c;
    margin-right: 0.6rem;
    text-decoration: line-through;
}
.hplwQp {
    font-size: 1.5rem;
    /*color: #696969;*/
}
.ibWilj {
    font-size: 1.2rem;
    /*color: #696969;*/
    margin-left: 0.5rem;
    display: inline-block;
}
.iytXjE {
    /*width: 50vw;*/
    width: 40vw;
}
.elxuhW {
    min-width: 15rem;
    min-height: 44px;
    display: inline-flex;
    -webkit-box-align: stretch;
    align-items: stretch;
    border-radius: 0.6rem;
    background: transparent;
    padding: 0px;
    border: none;
    cursor: pointer;
}
.ktQzRb {
    position: fixed;
    bottom: 0px;
    width: 100%;
    height: 6.5rem;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    background: rgb(255, 255, 255);
    z-index: 9;
    padding: 0px 1.5rem 0rem;
    box-shadow: rgb(0 0 0 / 20%) 0px 0px 10px;
}
.kwfqFr_org {
    /*display: inline-flex;
    vertical-align: middle;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    min-height: 44px;
    line-height: 44px;
    margin-top: 0px;
    margin-left: 0px;
    white-space: nowrap;
    font-size: 1.6rem;
    font-weight: 300;*/
    color: #ffffff;
    opacity: 1;
    background: border-box #ec4f62;
    border-color: #ec4f62;
    border-width: 0px;
    border-style: solid;
    border-radius: 0.6rem;
    /*padding: 0px 1.6rem;*/
    transition: transform 0.25s ease 0s, opacity 0.25s ease 0s, box-shadow 0.25s ease 0s;
    outline: none !important;
}
.kwfqFr {
    display: inline-flex;
    vertical-align: middle;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    min-height: 44px;
    line-height: 44px;
    margin-top: 0px;
    margin-left: 0px;
    white-space: nowrap;
    font-size: 1.6rem;
    font-weight: 300;
    color: #ffffff;
    opacity: 1;
    background: border-box #ec4f62;
    border-color: #ec4f62;
    border-width: 0px;
    border-style: solid;
    border-radius: 0.6rem;
    transition: transform 0.25s ease 0s, opacity 0.25s ease 0s, box-shadow 0.25s ease 0s;
    outline: none !important;
  }

  .kwfqFr.blink{
    -webkit-animation: glowing 1500ms infinite;
    -moz-animation: glowing 1500ms infinite;
    -o-animation: glowing 1500ms infinite;
    animation: glowing 1500ms infinite;
  }
  
  @-webkit-keyframes glowing {
    0% { background-color: #f35a6b; -webkit-box-shadow: 0 0 3px #e33434; }
    50% { background-color: #f5394e; -webkit-box-shadow: 0 0 40px #e53e3e; }
    100% { background-color: #f1596a; -webkit-box-shadow: 0 0 3px #d72c2c; }
  }
  
  @-moz-keyframes glowing {
    0% { background-color: #f35a6b; -moz-box-shadow: 0 0 3px #e33434; }
    50% { background-color: #f5394e; -moz-box-shadow: 0 0 40px #e53e3e; }
    100% { background-color: #f1596a; -moz-box-shadow: 0 0 3px #d72c2c; }
  }
  
  @-o-keyframes glowing {
    0% { background-color: #f35a6b; box-shadow: 0 0 3px #e33434; }
    50% { background-color: #f5394e; box-shadow: 0 0 40px #e53e3e; }
    100% { background-color: #f1596a; box-shadow: 0 0 3px #d72c2c; }
  }
  
  @keyframes glowing {
    0% { background-color: #f35a6b; box-shadow: 0 0 3px #e33434; }
    50% { background-color: #f5394e; box-shadow: 0 0 40px #e53e3e; }
    100% { background-color: #f1596a; box-shadow: 0 0 3px #d72c2c; }
  }

.kwfqFr > :not(:last-child):not(:only-child) {
    margin-right: 0.8rem;
    line-height: 1;
}
.dkwpEa {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
    font-size: inherit;
    transition: transform 0.4s ease 0s;
}
.gyojlT {
    transition: transform 0.4s ease 0s;
}
/*-----------------cart info start----------------*/
.jhziAY {
    padding: 0px 1rem;
    margin-top: -0.1rem;
    background-color: #ffffff;
}
.caijwq {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: #1c1c1c;
    opacity: 0.2;
    visibility: visible;
    transition: all 0.2s ease 0s;
    z-index: 9;
}
.gPGZST {
    display: flex;
    margin: 0px 0px 2rem;
    -webkit-box-align: center;
    align-items: center;
}
.cartwrapper {
    position: relative;
    max-width: 90rem;
    max-height: initial;
    margin: 0px auto;
    width: 100%;
}
.cartheading {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
}
.cartheadingtitle {
    font-weight: 400;
    color: #363636;
    font-size: 1.5rem;
    margin: 1.5rem 0px;
    user-select: none;
}
.fRCvfo {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
}
.byLLrW {
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    width: 24px;
    height: 24px;
}
.kSIUYJ {
    height: 36rem;
    overflow-y: auto;
    border-bottom: 0.1rem solid rgb(232, 232, 232);
    padding: 0 0 1.5rem 0;
}
.eWvKll {
    padding-bottom: 1rem;
}
.gnHEvw {
    margin: 0px 0px 1rem;
    border-top: none;
    border-right: none;
    border-left: none;
    border-image: initial;
    border-bottom: 1px solid #e8e8e8;
}
.cGXLsE > div > div {
    align-items: flex-start;
}
.doKdVK {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
}
.fFhIkZ {
    display: flex;
    flex-direction: column;
    padding-right: 0.5rem;
}
.eNNUsK {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
}
.btxdAi {
    font-size: 1.4rem;
    width: 80%;
    line-height: 2rem;
    color: #9c9c9c;
    margin: 0.5rem 0px 0px 2.3rem;
}
.cGXLsE > div > div > div:nth-child(2) > div:nth-child(1) {
    margin: 0.3rem 0px 1rem;
}
/*.cGXLsE > div > div > div:nth-child(2) {
    flex-direction: column-reverse;
}*/
.gzFKqN {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.iaSZFy {
    font-size: 1.6rem;
    margin: 0px;
    color: #363636;
    font-weight: 600;
}
.fuGMDN {
    line-height: 1.2rem;
    display: none;
    font-size: 1rem;
    margin-top: 4px;
    color: #f4a266;
}
.iitULE {
    margin-right: 1rem;
}
/*-----------------cart info end------------------*/

/*---------------show hide large image------------*/
.gahQYm {
    display: flex;
    /*flex-direction: column;*/
    flex-direction: column-reverse;
    border-bottom: 1px solid #e8e5e5;
}
.leftlargeimage {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #e8e5e5;
    position: relative;
}
.foGWxa {
    position: relative;
    margin-right: 0px;
    margin-bottom: 2rem;
    flex-shrink: 0;
    z-index: 0;
}
.dYbSLL {
    position: relative;
    max-width: 100%;
    width: 100%;
    height: 25rem;
    overflow: hidden;
}
/*---------------show hide large image------------*/

/*---------------notification message------------*/
.kJQFyq {
    line-height: 1.5;
    margin: 0px;
    background: #e4eefa;
    color: #2781e7;
    position: fixed;
    bottom: 0px;
    padding: 0px 1.2rem;
    left: 50%;
    opacity: 1;
    transition: all 0.3s ease 0s;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    justify-content: flex-start;
    width: 50rem;
    font-size: 1.4rem;
    height: 3.5rem;
    border-radius: 0.8rem;
    z-index: 10;
}
.kdnrAZ {
    margin: 0px 0.8rem 0px 0px;
    display: block;
    font-size: 1.4rem;
    line-height: 1.8rem;
    padding: 0.4rem 0.6rem;
    background: #2781e7;
    color: #ffffff;
    font-weight: 400;
    min-width: max-content;
    border-radius: 0.2rem;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.hwKGtB {
    line-height: 1.5;
    margin: 0px;
    background: #e4eefa;
    color: #2781e7;
    position: fixed;
    bottom: 0rem;
    padding: 0px 1.2rem;
    left: 50%;
    opacity: 0;
    transition: all 0.3s ease 0s;
    display: flex;
    align-items: flex-start;
    place-content: flex-start space-between;
    -webkit-box-pack: justify;
    width: 50rem;
    font-size: 1.4rem;
    height: 5rem;
    border-radius: 0.8rem;
    z-index: -1;
}
.hCBdfT {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    height: 100%;
}
.hspyDw {
    line-height: 1.5;
    margin: 0px;
    flex-shrink: 0;
    color: #4f4f4f;
    align-self: center;
    font-size: 1.2rem;
    font-weight: 400;
    cursor: pointer;
    border-bottom: 1px dashed #9c9c9c;
}
.hodrTk {
    background: linear-gradient(#ef3340 7.68%, #d92662 91.24%), #2781e7;
    padding: 0.8rem 0.6rem;
    border: none;
    border-radius: 5px;
    color: #ffffff;
    font-size: 1.2rem;
}
.iHzfnZ {
    line-height: 1.5;
    margin: 0px 0px 0px 1rem;
    padding: 0.3rem;
    font-size: 1rem;
    color: #2781e7;
}
.hSfokC {
    background-color: #ffffff;
    height: max-content;
    transform: translate3d(0px, 0px, 0px);
    opacity: 1;
    z-index: 1;
    display: block;
    padding: 2rem;
}
/*----------------------customization popup----------------------*/
.fgjele {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    min-width: max-content;
    height: 100%;
    /*height: 0rem;*/
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    visibility: visible;
    overflow: auto;
    z-index: 11;
    transition: all 0.2s ease 0s;
    transform: translate(0px, 0px);
}
.hcdmGE {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: #1c1c1c;
    opacity: 0.45;
    visibility: visible;
    transition: opacity 0.25s ease 0s;
}
.hyQzCb {
    background-color: #ffffff;
    height: max-content;
    transform: translate3d(0px, 0px, 0px);
    opacity: 1;
    z-index: 1;
    display: block;
    padding: 0 0 1rem 0;
}
.eggzWm {
    width: calc(100% + 0.5rem);
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    margin: 0px;
}
.gAkCTa {
    font-size: 1.9rem;
    line-height: 3rem;
    color: #1c1c1c;
    font-weight: 600;
    margin: 0px;
}
.jwGlki {
    max-height: 3rem;
    height: 3rem;
    width: 3rem;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    border-radius: 50%;
}
.fRCvfo {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
}
.oRYSe {
    display: block;
    overflow: initial;
}
.idQvOD {
    /*max-height: 80vh;*/
    /*max-height: 60vh;*/
    max-height: 70vh;
    overflow: hidden scroll;
    width: 60rem;
}
.cPwOJx {
    color: #696969;
    font-size: 16px;
    padding-top: 1rem;
}
.hPCrWT {
    border: 1px solid #e8e8e8;
    margin: 2rem 0px;
}
.wQCFM {
    margin-top: 1rem;
}
.idFvRo {
    overflow: hidden;
}
.gyBWSg {
    color: #1c1c1c;
    font-size: 2.2rem;
    position:relative;
}
.gfWojX {
    width: 200%;
    display: flex;
    position: relative;
    top: 0px;
    left: 0px;
    transition: all 0.2s ease 0s;
    transform: translateX(0px);
}
.ktfTIz {
    font-size: 14px;
    color: #cfcfcf;
    margin-top: 0.4rem;
    width: 50%;
}
.izyrQJ {
    font-size: 14px;
    color: #ed5a6b;
    margin-top: 0.4rem;
    font-weight: 400;
    width: 50%;
}
.fABuGy {
    /*margin-top: 2rem;*/
    margin-top: 0px;
}
.bmBDqp {
    display: flex;
    padding:5px 0;
    margin-bottom: 0.2rem;
}
.bmBDqp.selected{
    /*background:#9DDAED;*/
    background:#FFF6F7;
}
.dUGYrj {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 0px 0.5rem;
    width: 100%;
    /*margin-bottom: 1.6rem;*/
    align-items: center;
}
.fkUtKH {
    line-height: 1.5;
    margin: 0px;
    color: #363636;
    font-size: 2rem;
}
.ggJkFu {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}
.buNJKl {
    text-decoration: line-through;
    font-size: 1.4rem;
    color: #9c9c9c;
}
.jiHnaU {
    margin-left: 0.5rem;
}
.irrWnt {
    width: max-content;
    /*margin-bottom: 1.6rem;*/
    margin-right: 0px;
}
.jrHtYr {
    font-size: 15px;
    color: #1c1c1c;
    cursor: pointer;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: flex-start;
    justify-content: flex-start;
    height: 100%;
    line-height: 1.2;
}
.hwTXNN {
    flex-shrink: 0;
}
.iszoyV {
    stroke: rgb(156, 156, 156);
    stroke-width: 1px;
    fill: rgb(255, 255, 255);
}
.cmcsiK {
    fill: rgb(237, 90, 107);
}
.kTfLuB {
    stroke: rgb(237, 90, 107);
    stroke-width: 1px;
    fill: rgb(255, 255, 255);
}
.jlsszO {
    display: none;
}
.bIPSGt {
    height: 3.5rem;
    width: 3.5rem;
}
.jUrwkH {
    padding-top: 0.2rem;
    cursor: pointer;
    margin: 0px 0px 0.1rem 0.8rem;
}
.bmBDqp {
    display: flex;
}
.kesDMu {
    height: 5rem;
}
.cInTtE {
    font-size: 2.4rem;
    line-height: 2.8rem;
    font-weight: 400;
    color: rgb(28, 28, 28);
    margin-top: 0.5rem;
    margin-bottom: 0.83rem;
}
/*-----------checkbox option---------*/
.kIsyOb {
    flex-shrink: 0;
    margin-bottom: 0.8rem;
    margin-right: 0.2rem;
}
.oQgkb {
    font-size: 14px;
    line-height: 1.2;
    color: rgb(28, 28, 28);
    cursor: pointer;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: flex-start;
    justify-content: flex-start;
    height: 100%;
    width: max-content;
}
.iPRmnw {
    appearance: none;
    cursor: pointer;
    outline: 0px;
    border-radius: 4px;
    margin-right: 8px;
    width: 30px;
    height: 30px;
    background: rgb(255, 255, 255);
    border: 2px solid rgb(156, 156, 156);
    box-sizing: border-box;
    position: relative;
    transition: all 0.4s ease 0s;
}
.iPRmnw:checked {
    border: 2px solid rgb(237, 90, 107);
    background: rgb(255, 255, 255);
}
.iPRmnw:checked::after {
    position: absolute;
    top: 3.5%;
    left: 30%;
    width: 1rem;
    height: 2.5rem;
    content: "";
    border-right: 2px solid rgb(237, 90, 107);
    border-bottom: 2px solid rgb(237, 90, 107);
    border-top: 0px;
    border-left: 0px;
    transform: rotate(48deg);
}
/*-----------checkbox option---------*/

/*----------------------customization popup----------------------*/

/*----------------------repeat customization popup----------------------*/
.iBlUrl {
    display: none;
}
.gzwZLJ {
    background-color: rgb(255, 255, 255);
    height: max-content;
    transform: translate3d(0px, 0px, 0px);
    opacity: 1;
    z-index: 22;
    display: block;
    padding: 2rem 1.5rem;
}
.kCdFWE {
    border: 1px solid rgb(232, 232, 232);
    margin-top: 0.6rem;
}
.ItLLI {
    margin-top: 2rem;
}
.bbjjqK {
    font-weight: 400;
    margin-bottom: 1rem;
}
.enzpKS {
    color: rgb(130, 130, 130);
}
.yDyrk {
    margin-top: 3rem;
}
.HTAAf {
    position: relative;
    width: 100%;
    -webkit-box-flex: 0;
    flex-grow: 0;
    flex-shrink: 0;
    max-width: 50%;
    padding: 0px 5px 10px 0px;
}
.yDyrk button {
    width: 100%;
}
.ckiDDQ {
    height: 0px;
    width: 0px;
}
/*----------------------repeat customization popup----------------------*/

/*----------------------customization popup button----------------------*/
.teiDS {
    height: 6.5rem;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    position: fixed;
    bottom: 0px;
    width: 100%;
    background: #ffffff;
    left: 0px;
    padding: 1rem;
    box-shadow: rgb(0 0 0 / 10%) 0px -1px 20px;
    z-index: 1000;
    border-radius: 0px 0px 0.6rem 0.6rem;
}
.HdtOH {
    padding-right: 1rem;
}
.fJNrek {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
}
.ipHtgH {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 8.2rem;
    height: 4.4rem;
    border: 1px solid rgb(28, 28, 28);
    border-radius: 0.6rem;
    overflow: hidden;
    cursor: initial;
    user-select: none;
    background: #FFF6F7;
}
.hoSSCx {
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    width: 14px;
    height: 14px;
}
.fTsfFl {
    color: rgb(28, 28, 28);
    font-size: 1.4rem;
    margin: 0px 0.5rem;
    font-weight: 500;
}
.TZpZK {
    margin-top: 0.2rem;
}
.MxLSp {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    cursor: inherit;
}
.teiDS button {
    width: 100%;
}
.bXdRxo {
    display: inline-flex;
    vertical-align: middle;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    min-width: 15rem;
    min-height: 44px;
    line-height: 44px;
    margin-top: 0px;
    margin-left: 0px;
    white-space: nowrap;
    font-size: 1.6rem;
    font-weight: 300;
    color: #ffffff;
    opacity: 1;
    background: border-box #ed5a6b;
    border-color: #ed5a6b;
    border-width: 0px;
    border-style: solid;
    border-radius: 0.6rem;
    padding: 0px 1.6rem;
    transition: transform 0.25s ease 0s, opacity 0.25s ease 0s, box-shadow 0.25s ease 0s;
    outline: none !important;
}
.eYrDjb {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}
.bcvNdD {
    text-decoration: line-through;
    margin-left: 0.4rem;
}
.dkwpEa :last-child:not(:only-child) {
    margin-top: 0.1rem;
}
.btgzzv {
    margin-left: 0.6rem;
}
.haMdOJ {
    position: relative;
    overflow: hidden;
}
.haMdOJ {
    display: inline-flex;
    vertical-align: middle;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    min-width: 15rem;
    min-height: 44px;
    line-height: 44px;
    margin-top: 0px;
    margin-left: 0px;
    white-space: nowrap;
    font-size: 1.6rem;
    font-weight: 300;
    color: rgb(237, 90, 107);
    opacity: 1;
    background: border-box transparent;
    border-color: rgb(245, 112, 130);
    border-width: 0.1rem;
    border-style: solid;
    border-radius: 0.6rem;
    padding: 0px 1.6rem;
    transition: transform 0.25s ease 0s, opacity 0.25s ease 0s, box-shadow 0.25s ease 0s;
    outline: none !important;
}
.dKmxez {
    position: relative;
    width: 100%;
    -webkit-box-flex: 0;
    flex-grow: 0;
    flex-shrink: 0;
    max-width: 50%;
    padding: 0px 0px 10px 5px;
}
/*----------------------customization popup button----------------------*/

.optionname::after{
	content: ",";
}

.optionname:last-child::after{
	content: "";
}

.optionname.small{
    font-size: 1.2rem;
}

.itemtitle.livecounter
{
    color:#E3465D;
    font-style: italic;
    font-weight: normal;
    font-size: 1.4rem;
}
.itemtitle.selfservice
{
    color:#E3465D;
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    margin-left: 0;
    text-align: center;
}
.itemtitle.stockout
{
    color:#E3465D;
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
}
.appwidth50 {
    width: 50% !important;
    max-width: 50% !important;
    display: inline-flex;
}

.confirmseatbox .yes,
.selecttable .table,
.orderingerror .retry
{
    background: #9DDAED;
    width: 100%;
    color: #092147;
    border-radius: 35px;
    outline: none;
}
.selecttable .table{
    border-radius: 0px;
}
.selecttable .ordermessage{
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
}
.selecttable .availableseat{
    max-height: 36vh;
    overflow: auto;
    border-bottom: 2px solid #E3465D;
    padding: 0 0 5px 0;
}
.confirmseatbox .yes.orderplacing,
.confirmseatbox .yes:active.orderplacing
{
    background: #c7c7ca;
}
.confirmseatbox .no{
    background: #9DDAED;
    width: 100%;
    color: #092147;
    border-radius: 35px;
}
.confirmseatbox .yes,
.confirmseatbox .no{
    background-position: center;
    transition: background 0.8s;
}
.confirmseatbox .yes:hover,
.confirmseatbox .no:hover{
    background: #6bc2df radial-gradient(circle, transparent 1%, #6bc2df 1%) center/15000%;
}
.appelement{
    box-shadow: rgb(0 0 0 / 20%) 0px 0px 10px;
    /*background: rgb(255, 255, 255);*/
}
.form-group.cancelallpendingitem{
    background: #363636;
}
.newfirsttogglebox{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.8rem;
}

.tglspannew {
    display: inline-block;
    height: 2.6rem;
    appearance: none;
    outline: none;
}

.newfirsttogglebox input[type=checkbox]{
	height: 0;
	width: 0;
	visibility: hidden;
}
.newfirsttogglebox input[type=checkbox]+label {
	cursor: pointer;
	text-indent: -9999px;
	width: 4rem;
	height: 2.6rem;
	background: rgb(204, 204, 204);
	border-radius: 100px;
    position: relative;
}
.newfirsttogglebox input[type=checkbox]+label:after {
	content: '';
	position: absolute;
	top: 0rem;
	left: 0.2rem;
	width: 2.5rem;
	height: 2.5rem;
    background: #ffffff;
    border: 1px solid #b5b2b2;
	border-radius: 90px;
    transition: background-color 0.15s ease 0s, opacity 0.15s ease 0s;
}
.newfirsttogglebox input[type=checkbox]:checked + label {
	background: #50b547;
}
.newfirsttogglebox input[type=checkbox].containegg:checked + label {
	background: #f3c117;
}
.newfirsttogglebox input[type=checkbox]:checked + label:after {
	left: calc(100% - 0px);
    transform: translateX(-100%);
    background: #ffffff;
}
.newfirsttogglebox input[type=checkbox]+label:active:after {
	width: 2.5rem;
}
.newfirsttogglebox.secondtogglebox {
    margin-left: 20px;
}
.optioname{
	font-style:'italic';
	color:#50b547;
    font-size: 1.5rem;
}
.optioname::after{
	content: ",";
}
.optioname:last-child::after{
	content: "";
}
.noproductimgnomargin{
    position: unset !important;
    bottom:0px !important;
}
.newfilter{
    display: flex;
    justify-content: space-between;
}
.filtertitle{
    margin-left: .5rem;
    margin-right: .5rem;
    font-weight: 600;
}
.filtertitle3{
    width: 15%;
}
.filtertitle2{
    width: 30%;
}
.filtertitle1{
    width: 60%;
}
.newfiltertoggle{
    display: flex;
    justify-content: space-between;
    margin-right: .8rem;
}
.newfiltertoggle1{
    width: unset;
}
.newfiltertoggle2{
    width: 70%;
}
.newfiltertoggle3{
    width: 85%;
}
.newfiltertoggle .newfilter{
    background-color: #ffffff;
    border-color: #E0E0E0;
    border-radius: 2.6rem;
    border-width: 1px;
    border-style: solid;
    padding: .5rem .8rem;
    align-items: center;
    font-size:1.25rem;
    box-shadow: rgb(0 0 0 / 20%) 0px 1px 2px 0px;
}
.newfiltertoggle .newfilter.activeveg{
    background-color: #EAFFF0;
    padding: .5rem .5rem;
}
.newfiltertoggle .newfilter.activeegg{
    background-color: #FFFDF5;
    padding: .5rem .5rem;
}
.newfiltertoggle .newfilter.activenonveg{
    background-color: #FFF6F7;
    padding: .5rem .5rem;
}
.itemooptioncatrequired
{
    font-size: 1.5rem;
    color: #ef5b6c;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 8.2rem;
    padding: 0 .9rem;
    border: 1px solid #d6c7d1;
    border-radius: 0.5rem;
    overflow: hidden;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background: #FFF6F7;
    position: absolute;
    top: 0.5rem;
    right: 1.1rem;
}
.optioncatname{
    width:78%;
    font-weight: 600;
    font-size: 2rem;
}
.cartheader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #F0F0F0;
    margin-bottom: .5rem;
}
.cartsummary,
.viewcart,
.cartblock{
    display: flex;
}
.cartblock{
    justify-content: space-between;
    width: 100%;
    height: 5.2rem;
    align-items: center;
    padding: 0 1rem;
    background: #ec4f62;
    color: #ffffff;
    border-radius: .5rem;
}
.dXkqRz {
    margin: 1.5rem 0px 0px;
}
.dfUaKd {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    margin: 0px 1rem;
}
.dBKdLv {
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: rgb(105, 105, 105);
    border-bottom: 1px dashed rgb(130, 130, 130);
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 1rem;
}
.close-button {
    width: 45px;
    height: 45px;
    border-radius: 100px;
    background: #000;
    display: block;
    z-index: 200;
    text-indent: -9999px;
}
.close-button:before,
.close-button:after {
    content: '';
    width: 55%;
    height: 3px;
    background: #fff;
    position: absolute;
    top: 48%;
    left: 22%;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}
.close-button:after {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}
.ffxirw {
    padding: 1.3rem 1.2rem;
    background: rgb(255, 255, 255);
    border: 0.1rem solid rgb(207, 207, 207);
    font-size: 18px;
    width: 100%;
    outline: none;
    box-sizing: border-box;
    border-radius: 0.6rem;
    resize: none;
    color: #1c1c1c;
    overflow: auto;
}
.fJUPqY {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin: 0px 1rem;
}
.KCZVC {
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: rgb(237, 90, 107);
    text-transform: uppercase;
}
.gQyQJp {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    margin: 5rem 0px;
    width: 100%;
}
.bAgJbS {
    position: relative;
    max-width: 100%;
    width: 26.4rem;
    height: 16.5rem;
    overflow: hidden;
}
.dqsEmh {
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    background: linear-gradient(to right, rgb(248, 248, 248) 0%, rgb(255, 255, 255) 10%, rgb(248, 248, 248) 40%, rgb(248, 248, 248) 100%) no-repeat rgb(248, 248, 248);
    opacity: 0;
    transition: opacity 0.25s ease-out 0s;
    will-change: opacity;
    border-radius: inherit;
    animation: 1.5s linear 0s infinite normal forwards running jCOzbu;
}
.cNjMLA {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transform: none;
    opacity: 1;
    will-change: transform, opacity;
    border-radius: inherit;
    filter: unset;
    transition: opacity 0.25s ease 0s, transform 0.25s ease 0s;
}
.gQyQJp p {
    font-size: 1.3rem;
    line-height: 1.7rem;
    color: rgb(79, 79, 79);
}
@-webkit-keyframes qty_slider_animation_pink {
    from {left: 0px}
    to {left: 60px}
}

@keyframes qty_slider_animation_pink {
    from {left: 0px}
    to {left: 60px}
}
.qty_slider_animation_pink
{
    position: absolute;
    bottom: 0rem;
    right: 0;
    width: 20px;
    height: .4rem;
    display: block;
    background-color: #d42d47;
    -webkit-animation: qty_slider_animation_pink 0.6s ease infinite;
    animation: qty_slider_animation_pink 0.6s ease infinite;
}
@-webkit-keyframes qty_slider_animation_white {
    from {left: 0px}
    to {left: 60px}
}

@keyframes qty_slider_animation_white {
    from {left: 0px}
    to {left: 60px}
}
.qty_slider_animation_white
{
    position: absolute;
    bottom: 0rem;
    right: 0;
    width: 20px;
    height: .4rem;
    display: block;
    background-color: #FFF6F7;
    -webkit-animation: qty_slider_animation_white 0.6s ease infinite;
    animation: qty_slider_animation_white 0.6s ease infinite;
}
#cancel-runner-order .MuiTypography-h6{
    font-size:2rem
}
.MuiTypography-body1{
    font-size:1.5rem !important
}
.MuiDialogTitle-root .MuiTypography-root{
    font-size:2rem !important
}

blockquote.outfordeliver,
p.outfordeliver {
  margin: 0;
  padding: 0;
}

.l-quote {
  position: relative;
  width: 98%;
  padding: 15px 25px 20px;
  margin: 15px auto;
  color: #fff;
  background: #1abc9c;
  border-radius: 5px;
}

.quote:after {
  -moz-transform: skewX(-15deg);
  -ms-transform: skewX(-15deg);
  -webkit-transform: skewX(-15deg);
  transform: skewX(-15deg);
  content: "";
  position: absolute;
  top: 100%;
  left: 45%;
  border-width: 35px 30px 0 0;
  border-style: solid;
  border-color: #1abc9c transparent;
}

blockquote.outfordeliver {
  font: italic 26px / 1.8 "VarelaRound-Regular",Helvetica,sans-serif;
  display:inline-block;
}

.imagepreviewblock{
    width: 48%;
    float: left;
    margin: 5px;
}

.servicedisablebox {
    position: fixed;
    bottom: 6.5rem;
    width: 100%;
    height: 6.5rem;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: center;
    background: #ff0000;
    color:#ffffff;
    z-index: 9;
    padding: 0px 1.5rem 0rem;
    box-shadow: rgb(0 0 0 / 20%) 0px 0px 10px;
}
.servicedisablebox.orderdelivered{
    background: #1abc9c;
    justify-content: space-between;
}
.servicedisablebox.withoutcart{
    bottom: 0;
}
.servicedisablebox.withcart{
    bottom: 6.5rem;
}
.menubtnwitoutcart.servicedisablemsg,
.menubtnwitoutcartstatus.servicedisablemsg{
    bottom: 10rem;
}
.menubtnwithcart.servicedisablemsg,
.menubtnwitcartstatus.servicedisablemsg{
    bottom: 15rem;
}
.menubtnwitoutcart.caption.servicedisablemsg {
    bottom: 15.5rem;
}
.menubtnwithcart.caption.servicedisablemsg {
    bottom: 20.5rem;
}
.Toastify__toast-container--bottom-center{
    max-width: 500px !important;
    width: 100% !important;
}
.Toastify__toast--error{
    /*background: #323232 !important;*/
    background: #E3465D !important;
    color: #ffffff !important;
    border-radius: 5px !important;
}
.Toastify__toast--success{
    background: #2DD284 !important;
    color: #ffffff !important;
    border-radius: 5px !important;
}
.Toastify__toast{
    min-height: 50px !important;
}
.Toastify__close-button{
    color: #ffffff !important;
}
.Toastify__close-button--light{
    opacity: 0.9 !important;
}

.Toastify__toast--error .Toastify__toast-body .Toastify__toast-icon svg,
.Toastify__toast--success .Toastify__toast-body .Toastify__toast-icon svg{
    fill:#ffffff
}
.MuiDialog-paperWidthSm{
    min-width: 400px !important;
}
.MuiDialog-root{
    z-index: 9999 !important;
}
#backtotop {
    display: none;
    position: fixed;
    bottom: 20px;
    left: 30px;
    z-index: 99;
    border: none;
    outline: none;
    background-color: #363636;
    color: white;
    border-radius: 50%;
    font-size: 18px;
    width:50px;
    height: 50px;
  }
  #backtotop:hover {
    background-color: #555;
  }
  #backtotop.menubtnwitoutcart.servicedisablemsg,
  #backtotop.menubtnwithcart{
      bottom: 10rem;
  }
  #backtotop.menubtnwithcart.servicedisablemsg{
    bottom: 15rem;
  }
  canvas.demoscratchclass{
    position:absolute;
    z-index: 1;
  }
  .demoscratchcard{
    position:relative;
    min-height: 200px;
    width: 200px;
  }
  .scratchcardtext{
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    line-height: 20rem;
    text-align: center;
    z-index: 0;
  }
  .Toastify__toast-container--bottom-center{
    /*bottom: 3.4em !important;*/
    z-index: 1 !important;
  }
  .upcommingrunners {
    position: fixed;
    bottom: 50px;
    width: 100%;
    height: 6.5rem;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: center;
    background: #e8e8e8;
    z-index: 9;
    padding: 0px 1.5rem 0rem;
    box-shadow: rgb(0 0 0 / 20%) 0px 0px 10px;
}

.upcommingrunners .releaserunner-btn,
.upcommingrunners .disablerunner-btn {
	font-size: 15px;
	line-height: 1.5;
	color: #fff;
	height: 40px;
	background: #E3465D;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	justify-content: center;
	align-items: center;
	padding: 0 20px;
	-webkit-transition: all 0.4s;
	-o-transition: all 0.4s;
	-moz-transition: all 0.4s;
	transition: all 0.4s;
	display:inline;
}

.upcommingrunners .releaserunner-btn {
	border-radius: 0 5px 5px 0;
}

.upcommingrunners .disablerunner-btn {
	border-radius: 5px;
}

.managewrapper input[type=checkbox]{
	height: 0;
	width: 0;
	visibility: hidden;
}

.managewrapper input[type=checkbox]+label {
	cursor: pointer;
	text-indent: -9999px;
	width: 45px;
	height: 22px;
	background: #b5b2b2;
	display: inline-block;
	border-radius: 100px;
	position: relative;
	margin: 0;
	line-height: 20px;
}
.managewrapper input[type=checkbox]+label:after {
	content: '';
	position: absolute;
	top: 1px;
	left: 0px;
	width: 20px;
	height: 20px;
    background: #ffffff;
    border: 1px solid #b5b2b2;
	border-radius: 90px;
	transition: 0.3s;
}
.managewrapper input[type=checkbox]:checked + label {
	background: #82ebd4;
}
.managewrapper input[type=checkbox]:checked + label:after {
	left: calc(100% - 0px);
    transform: translateX(-100%);
    border: 1px solid #ffffff;
	background: #008265;
	content: "On";
	color: #ffffff;
}
.managewrapper input[type=checkbox]+label:active:after {
	width: 20px;
}
.managewrapper.defaultevent{
	display: inline;
}
.Toastify{
    z-index: 2222;
    position: absolute;
}
.welcome-section{
    /*width: 60vw;*/
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}
.hellotext{
    font-size: 25px;
    text-align: center;
}
.releasingrunnertext{
    font-size: 120px;
    background: #ff0000;
    width: 200px;
    color: #ffffff;
    margin: auto;
}